import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import DistributionListTable from './Components/DistributionListTable';
import AddIcon from '@mui/icons-material/Add';
import { USER_STORAGE_KEY } from '../../../config/helpers/variables';
const DistributionList = () => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  return (
    <UserPage>
      <main className='distributionList'>
        <header className='distributionList__headerContainer'>
          <div className='distributionList__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='distributionList__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='distributionList__headerContainer__header__heading'>Distribution List</h3>
          </div>
          {user?.data?.role === "Administrator" &&
            <div className='distributionList__headerContainer__submitButton'>
              <Button onClick={() => navigate("/distribution_list/add")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Distribution List</Button>
            </div>
          }
        </header>
        <section className='distributionList__box'>
          <DistributionListTable />
        </section>
      </main>
    </UserPage>
  );
}

export default DistributionList;
