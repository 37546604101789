import React from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

const UserWebSignageForm = ({ webSignage, setWebSignage, handleSubmit }) => {

  const handleChange = e => {
    const { name, value } = e.target;
    setWebSignage({
      ...webSignage, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={webSignage.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='number'
          name='scroller_speed'
          label='Scroll Speed (5~20)'
          onChange={handleChange}
          value={webSignage.scroller_speed}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_title_width'
          label='Landscape Title Width'
          onChange={handleChange}
          value={webSignage.landscape_title_width}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_title_height'
          label='Landscape Title Height'
          onChange={handleChange}
          value={webSignage.landscape_title_height}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_title_top'
          label='Landscape Title Top'
          onChange={handleChange}
          value={webSignage.landscape_title_top}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_title_left'
          label='Landscape Title Left'
          onChange={handleChange}
          value={webSignage.landscape_title_left}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_description_width'
          label='Landscape Description Width'
          onChange={handleChange}
          value={webSignage.landscape_description_width}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_description_height'
          label='Landscape Description Height'
          onChange={handleChange}
          value={webSignage.landscape_description_height}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_description_top'
          label='Landscape Description Top'
          onChange={handleChange}
          value={webSignage.landscape_description_top}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='landscape_description_left'
          label='Landscape Description Left'
          onChange={handleChange}
          value={webSignage.landscape_description_left}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_title_width'
          label='Potrait Title Width'
          onChange={handleChange}
          value={webSignage.potrait_title_width}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_title_height'
          label='Potrait Title Height'
          onChange={handleChange}
          value={webSignage.potrait_title_height}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_title_top'
          label='Potrait Title Top'
          onChange={handleChange}
          value={webSignage.potrait_title_top}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_title_left'
          label='Potrait Title Left'
          onChange={handleChange}
          value={webSignage.potrait_title_left}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_description_width'
          label='Potrait Description Width'
          onChange={handleChange}
          value={webSignage.potrait_description_width}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_description_height'
          label='Potrait Description Height'
          onChange={handleChange}
          value={webSignage.potrait_description_height}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_description_top'
          label='Potrait Description Top'
          onChange={handleChange}
          value={webSignage.potrait_description_top}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='potrait_description_left'
          label='Potrait Description Left'
          onChange={handleChange}
          value={webSignage.potrait_description_left}
          required={true}
          style={{ width: '100%'}}
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default UserWebSignageForm;
