import React, { useState, useEffect } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import useTemplate from '../../../../hooks/useTemplate';
import { API_KEY } from '../../../../config/helpers/variables';
import AlertMessage from '../../../../components/AlertMessage';

const ViewTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAllTemplate } = useTemplate();
  const [template, setTemplate] = useState();
  const [errorMessage, setErrorMessage] = useState(null)

  const getTemplates = async () => {
    const response = await getAllTemplate()
    let templateData = response.templates.find(item => item.id == id);
    if (response.status < 300) {
      setTemplate(templateData);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }


  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='viewTemplate'>
        <header className='viewTemplate__headerContainer'>
          <div className='viewTemplate__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='viewTemplate__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='viewTemplate__headerContainer__header__heading'>View Template</h3>
          </div>
          <div className='viewTemplate__headerContainer__submitButton'>
            <Button onClick={() => navigate(`/notification/${template?.id}`)}>Send Notification</Button>
          </div>
        </header>
        <section className='viewTemplate__box'>
          <div className='viewTemplate__box__view' style={{ backgroundColor: `${template?.background_color}` }}>
            <h1 className='viewTemplate__box__view__name' style={{ color: `${template?.font_color}` }}>{template?.name}</h1>
            <p className='viewTemplate__box__view__subject' style={{ color: `${template?.font_color}` }}>{template?.subject}</p>
            <p className='viewTemplate__box__view__body' style={{ color: `${template?.font_color}` }}>{template?.body}</p>
            {template?.audio && <audio className='viewTemplate__box__view__voiceButton' src={`${API_KEY}${template?.audio?.url}`} controls />}
          </div>
        </section>
      </main>
    </UserPage>
  );
}

export default ViewTemplate;
