import React, { useEffect, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Link, useNavigate } from 'react-router-dom';
import TemplateCardsMenu from './TemplateCardsMenu';
import useTemplate from '../../../../hooks/useTemplate';
import { USER_STORAGE_KEY } from '../../../../config/helpers/variables';
import AlertMessage from '../../../../components/AlertMessage';

const TemplateCards = () => {
  const session = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const currentUser = session.data;
  const navigate = useNavigate();
  const { getAllTemplate } = useTemplate();
  const [templatesList, setTemplatesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)

  const getTemplates = async () => {
    const response = await getAllTemplate();
    if (response.status < 300) {
      setTemplatesList(response.templates);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getTemplates();
  }, []);


  return (
    <main className='dashboard__box__notifications'>
      <AlertMessage errorMessage={errorMessage} />
      {templatesList.map((template, index) => (
        <div className='dashboard__box__notifications__notificationBox' style={{ backgroundColor: `${template.background_color}` }} key={template?.id}>
          {(template.creator_id == currentUser.id && template.creator_type == "user") && <span className='dashboard__box__notifications__notificationBox__menuIcon'><TemplateCardsMenu menuColor={`${template.font_color} `} id={template.id} templatesList={templatesList} setTemplatesList={setTemplatesList} /></span>}
          <div onClick={() => navigate(`/viewtemplate/${template?.id}`)}>
            <p className='dashboard__box__notifications__notificationBox__name' style={{ color: `${template?.font_color}` }}>{template.name.length > 10 ? template?.name.slice(0, 10) + "..." : template?.name}</p>
            <p className='dashboard__box__notifications__notificationBox__subject' style={{ color: `${template?.font_color}` }}>{template.subject.length > 12 ? template?.subject.slice(0, 12) + "..." : template?.subject}</p>
          </div>
        </div>
      ))}
      <Link to="/template" className='dashboard__box__notifications__notificationBox'>
        <p className='dashboard__box__notifications__notificationBox__addIcon'><AddCircleOutlineOutlinedIcon /></p>
        <p className='dashboard__box__notifications__notificationBox__subject'>Add Template</p>
      </Link>
    </main>
  );
}

export default TemplateCards;
