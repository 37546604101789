import React from "react";
import AdminNav from "./AdminComponents/AdminNav";

const AdminPage = ({ children }) => {
  return (
    <div className='page'>
      <AdminNav />

      {children}
    </div>
  );
};

export default AdminPage;
