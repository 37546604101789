import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import WebPageDeleteModal from './WebPageDeleteModal';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';
import useWebPages from '../../../../hooks/useWebPages';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SuccessMessage from '../../../../components/SuccessMessage';

export default function WebPagesTable() {
  const navigate = useNavigate();
  const { getAllWebPages } = useWebPages();
  const [webPagesList, setWebPagesList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [webPageData, setWebPageData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [text, setText] = useState('');

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessMessage('Text copied to clipboard');
    } catch (err) {
      setErrorMessage('Failed to copy text: ', err);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? webPagesList.filter((item) => item?.name.toLowerCase().includes(value.toLowerCase()))
        : webPagesList
    setWebPageData(searchFilter)
  }

  const getWebPages = async () => {
    const response = await getAllWebPages();
    if (response.status < 300) {
      setWebPagesList(response.web_pages);
      setWebPageData(response.web_pages);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getWebPages();
  }, []);

  return (
    <>
      <SuccessMessage successMessage={successMessage} />
      <AlertMessage errorMessage={errorMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead'>Title</TableCell>
              <TableCell className='table__tableHead'>URL</TableCell>
              <TableCell className='table__tableHead' align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {webPageData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row?.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row?.title}
                </TableCell>
                <TableCell className='table__tableRow' align="left">
                  {row?.url}
                  <input type="text" value={text} onChange={(e) => setText(e.target.value)} style={{ outline: "none", border: "none" }} />
                  <IconButton style={{ marginLeft: "1rem" }}>
                    <ContentCopyIcon onClick={() => handleCopy(row?.url)} />
                  </IconButton>
                </TableCell>
                <TableCell className='table__tableRow' style={{ display: "flex", justifyContent: "center" }} >
                  <IconButton
                  // onClick={() => navigate(`/webpages/${row.id}/edit`)}
                  >
                    <BorderColorIcon sx={{ color: "blue", fontSize: "1.2rem" }} />
                  </IconButton>
                  {/* <WebPageDeleteModal id={row.id} webPageData={webPageData} setWebPageData={setWebPageData} /> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={webPageData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
