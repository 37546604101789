export const API_KEY = process.env.REACT_APP_BACKEND_BASE_URL;
export const FRONT_API_KEY = process.env.REACT_APP_BACKEND_BASE_URL;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;

export const USER_STORAGE_KEY = process.env.REACT_APP_USER_STORAGE_KEY;
export const ADMIN_STORAGE_KEY = process.env.REACT_APP_ADMIN_STORAGE_KEY;

export const THEME_COLOR = "#1e293b";
export const TOPBAR_COLOR = "#2d3645";
export const LIGHT_BLACK = "rgba(0, 0, 0, 0.8)";
