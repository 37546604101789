import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import AdminPackagesTable from './Components/AdminPackagesTable';
import AddIcon from '@mui/icons-material/Add';

const AdminPackages = () => {
  const navigate = useNavigate();

  return (
    <AdminPage>
      <main className='adminPackages'>
        <header className='adminPackages__headerContainer'>
          <div className='adminPackages__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='adminPackages__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='adminPackages__headerContainer__header__heading'>Packages</h3>
          </div>
          <div className='adminPackages__headerContainer__submitButton'>
            <Button onClick={() => navigate("/admin/packages/add")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Package</Button>
          </div>
        </header>
        <section className='adminPackages__box'>
          <AdminPackagesTable />
        </section>
      </main>
    </AdminPage>
  );
}

export default AdminPackages;
