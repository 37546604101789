import axios from 'axios';
import { API_KEY, USER_STORAGE_KEY, ADMIN_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useProfile = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };


  const updateAdminProfile = async admin_profile => {
    const params = {
      admin: {
        name: admin_profile.name,
        email: admin_profile.email,
        password: admin_profile.password,
        password_confirmation: admin_profile.password_confirmation,
      }
    }

    const response = await axios.put(
      `${API_KEY}/admins`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateProfile = async user_profile => {
    const params = {
      user: {
        first_name: user_profile.first_name,
        last_name: user_profile.last_name,
        email: user_profile.email,
        password: user_profile.password,
        password_confirmation: user_profile.password_confirmation,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAdminProfileById = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/edit`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getProfileById = async () => {
    const response = await axios.edit(
      `${API_KEY}/users/edit`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  return {
    updateAdminProfile, getAdminProfileById, getProfileById, updateProfile,
  };
};
export default useProfile;
