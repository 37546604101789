import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionsDetails = ({ user }) => {
  const navigate = useNavigate();
  return (
    <section className='subscriptionsDetails'>
      <header className='subscriptionsDetails__header'>
        <h1 className='subscriptionsDetails__header__heading'>My Subscription</h1>
        <span className='subscriptionsDetails__header__subscriptionId'>ID: <p>{user?.data?.subscription?.id}</p></span>
      </header>
      <div className='subscriptionsDetails__currentPlan'>
        <h1 className='subscriptionsDetails__currentPlan__heading'>Current plan</h1>
        <span className='subscriptionsDetails__currentPlan__name'>
          {user?.data?.subscription?.package_name}
        </span>
        <span className='subscriptionsDetails__currentPlan__duration'>
          ${user?.data?.subscription?.package_price} for {user?.data?.subscription?.subscription_duration}
        </span>
      </div>
      <span className='subscriptionsDetails__subscriptionDate'>Subscription start: {user?.data?.subscription?.start_date}</span>
      <span className='subscriptionsDetails__expiration'>Subscription expires: {user?.data?.subscription?.end_date}</span>
      {/* <span className='subscriptionsDetails__expiration'>Days remaining: {user.data.subscription.start_date - user.data.subscription.end_date}</span> */}
      {user?.data?.role === "Administrator" &&
        <Button variant='contained' className='subscriptionsDetails__viewPlanButton' color='success' onClick={() => navigate("/packages")}>View Plans</Button>
      }
    </section>
  );
}

export default SubscriptionsDetails;
