import React, { useState } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import UserEndpointGroupForm from '../Components/UserEndpointGroupForm';
import useEndpointGroups from '../../../../hooks/useEndpointGroups';
import AlertMessage from '../../../../components/AlertMessage';

const AddUserEndpointGroup = () => {
  const { createUserEndpointGroup } = useEndpointGroups();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [endpointGroup, setEndpointGroup] = useState({
    name: "",
    description: "",
    endpoint_type: "",
  });


  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in endpointGroup) {
      if (!endpointGroup[prop]) return alert(`Please fill the form correctly!`);
    }
    const response = await createUserEndpointGroup(endpointGroup);
    if (response.status < 300) {
      navigate("/userendpointgroups");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='endpointGroup'>
        <header className='endpointGroup__headerContainer'>
          <div className='endpointGroup__headerContainer__header'>
            <IconButton onClick={() => navigate("/userendpointgroups")} className='endpointGroup__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpointGroup__headerContainer__header__heading'>Add Endpoint Group</h3>
          </div>
        </header>
        <section className='endpointGroup__box'>
          <UserEndpointGroupForm endpointGroup={endpointGroup} setEndpointGroup={setEndpointGroup} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default AddUserEndpointGroup;
