import { Paper } from '@mui/material';
import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import SystemTabs from './Components/SystemTabs';

const System = () => {
  const navigate = useNavigate();
  return (
    <AdminPage>
      <main className='system'>
        <header className='system__headerContainer'>
          <div className='system__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='system__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='system__headerContainer__header__heading'>System</h3>
          </div>
        </header>
        <Paper className='system__box'>
          <SystemTabs />
        </Paper>
      </main>
    </AdminPage>
  );
}

export default System;
