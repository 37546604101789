import { Button } from '@mui/material';
import React, { useState } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import EndpointGroupForm from '../Components/EndpointGroupForm';
import useEndpointGroups from '../../../../hooks/useEndpointGroups';
import AlertMessage from '../../../../components/AlertMessage';

const AddEndpointGroup = () => {
  const { createEndpointGroup } = useEndpointGroups();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [endpointGroup, setEndpointGroup] = useState({
    name: "",
    description: "",
    endpoint_type: "",
  });


  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in endpointGroup) {
      if (!endpointGroup[prop]) return alert(`Please fill the form correctly!`);
    }
    const response = await createEndpointGroup(endpointGroup);
    if (response.status < 300) {
      navigate("/admin/endpointgroup");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='endpointGroup'>
        <header className='endpointGroup__headerContainer'>
          <div className='endpointGroup__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/endpointgroup")} className='endpointGroup__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpointGroup__headerContainer__header__heading'>Add Endpoint Group</h3>
          </div>
        </header>
        <section className='endpointGroup__box'>
          <EndpointGroupForm endpointGroup={endpointGroup} setEndpointGroup={setEndpointGroup} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AddEndpointGroup;
