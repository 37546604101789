import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AdminUserDeleteModal from './AdminUserDeleteModal';
import useUsers from '../../../../hooks/useUsers';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';

export default function AdminUsersTable() {
  const navigate = useNavigate();
  const { getAllAdminUsers } = useUsers();
  const [usersList, setUsersList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [usersData, setUsersData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? usersList.filter((item) => item?.email.toLowerCase().includes(value.toLowerCase()) || item?.first_name?.toLowerCase().includes(value.toLowerCase()) || item?.last_name?.toLowerCase().includes(value.toLowerCase()) || item?.company?.name?.toLowerCase().includes(value.toLowerCase()))
        : usersList
    setUsersData(searchFilter)
  }

  const getUsers = async () => {
    const response = await getAllAdminUsers();
    if (response.status < 300) {
      setUsersList(response.users);
      setUsersData(response.users);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }


  useEffect(() => {
    getUsers();
  }, []);


  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead'>User Id</TableCell>
              <TableCell className='table__tableHead'>First Name</TableCell>
              <TableCell className='table__tableHead'>Last Name</TableCell>
              <TableCell className='table__tableHead'>Email</TableCell>
              <TableCell className='table__tableHead'>Company Name</TableCell>
              <TableCell className='table__tableHead'>Role</TableCell>
              <TableCell className='table__tableHead'>Status</TableCell>
              <TableCell className='table__tableHead'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row?.id}
                </TableCell>
                <TableCell className='table__tableRow' align="left">{row?.first_name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.last_name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.email}</TableCell>
                <TableCell className='table__tableRow' align="left" style={{ textTransform: "capitalize" }}>{row?.company?.name}</TableCell>
                <TableCell className='table__tableRow' align="left" style={{ textTransform: "capitalize" }}>{row?.role}</TableCell>
                <TableCell className='table__tableRow' align="left" style={{ textTransform: "capitalize" }}>{row?.status}</TableCell>
                <TableCell className='table__tableRow' style={{ display: "flex", justifyContent: "left" }}>
                  <IconButton onClick={() => navigate(`/admin/edituser/${row.id}`)}>
                    <BorderColorIcon sx={{ color: "blue", fontSize: "1.2rem" }} />
                  </IconButton>
                  <AdminUserDeleteModal id={row.id} usersData={usersData} setUsersData={setUsersData} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={usersData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}