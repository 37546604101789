import React, { useState } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import useUsers from '../../../../hooks/useUsers';
import AdminUserForm from '../Components/AdminUserForm';
import AlertMessage from '../../../../components/AlertMessage';

const AddAdminUser = () => {
  const { createAdminUser } = useUsers();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    company_id: "",
    status: "",
    role: "Administrator",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      ...user,
    };
    const response = await createAdminUser(data);
    navigate("/admin/users");
    if (response.status < 300) {
      navigate("/admin/users");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='users'>
        <header className='users__headerContainer'>
          <div className='users__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/users")} className='users__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='users__headerContainer__header__heading'>Add User</h3>
          </div>
        </header>
        <section className='users__box'>
          <AdminUserForm user={user} setUser={setUser} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AddAdminUser;