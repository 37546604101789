import React from "react";

const CFWButton = ({ title, onClick, classes }) => {
  return (
    <button className={`cFWButton ${classes}`} onClick={onClick}>
      {title}
    </button>
  );
};

export default CFWButton;
