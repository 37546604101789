import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useNotificationHistory from "../../../../hooks/useNotificationHistory";
import NotificationHistoryDeleteModal from "./NotificationHistoryDeleteModal";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TablePagination from "@mui/material/TablePagination";
import AlertMessage from "../../../../components/AlertMessage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
export default function NotificationHistoryTable() {
  const navigate = useNavigate();
  const { getAllNotificationHistory } = useNotificationHistory();
  const [notificationsList, setNotificationsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [notificationData, setNotificationData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    const searchFilter =
      value !== ""
        ? notificationsList.filter(
            (item) =>
              item?.template?.name
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              item?.endpoint?.name
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              item?.user?.name.toLowerCase().includes(value.toLowerCase())
          )
        : notificationsList;
    setNotificationData(searchFilter);
  };

  const getNotificationHistory = async () => {
    debugger
    const response = await getAllNotificationHistory();
    if (response.status < 300) {
      setNotificationsList(response.notifications);
      setNotificationData(response.notifications);
    } else {
      setErrorMessage("Something went wrong!");
    }
  };

  useEffect(() => {
    debugger
    getNotificationHistory();
  }, []);

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className="tableSearchBox">
        <input
          type="text"
          placeholder="Search"
          onChange={handleChangeSearch}
          name="search"
          className="tableSearchBox__searchInput"
        />
        <SearchOutlinedIcon className="tableSearchBox__searchIcon" />
      </div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className="table"
        >
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className="table__tableHead">User Name</TableCell>
              <TableCell className="table__tableHead">Template Name</TableCell>
              <TableCell className="table__tableHead" align="left">
                Endpoint Name
              </TableCell>
              <TableCell className="table__tableHead" align="left">
                Creation Date
              </TableCell>
              <TableCell className="table__tableHead" align="left">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className="table__tableRow"
                    component="th"
                    scope="row"
                  >
                    {row?.user?.name}
                  </TableCell>
                  <TableCell className="table__tableRow" align="left">
                    {row?.template?.name}
                  </TableCell>
                  <TableCell className="table__tableRow" align="left">
                    {row?.endpoint?.name}
                  </TableCell>
                  <TableCell className="table__tableRow" align="left">
                    {row?.created_at}
                  </TableCell>
                  <TableCell
                    className="table__tableRow"
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    <IconButton
                      onClick={() => navigate("/viewnotificationhistory")}
                    >
                      {" "}
                      <VisibilityIcon
                        sx={{ fontSize: "1.2rem", marginTop: "0.3rem" }}
                      />
                    </IconButton>
                    <NotificationHistoryDeleteModal
                      id={row?.id}
                      notificationData={notificationData}
                      setNotificationData={setNotificationData}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={notificationData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
