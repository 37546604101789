import axios from "axios";
import { API_KEY, USER_STORAGE_KEY,ADMIN_STORAGE_KEY } from "../config/helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useNotificationHistory = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));
  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };
  const getAllNotificationHistory = async () => {
    const response = await axios
      .get(`${API_KEY}/users/notification_histories`, getHeaders())
      .then((res) => {
        if (res.data.status > 300) {
          handleErrors(res);
        }
        return res.data;
      });
    return response;
  };
  return {
    getAllNotificationHistory,
  };
};
export default useNotificationHistory;
