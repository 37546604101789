import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import useWebPages from '../../../hooks/useWebPages'

function HtmlPage() {
    const {id, access_token} = useParams()
    const [pageContent, setPageContent] = useState()
    const { getHtmlContent } = useWebPages();

    const getHtmlcontent = async () => {
        const response = await getHtmlContent(id, access_token);
        if (response.status < 300) {
            setPageContent(response?.data);
        } else {
            setPageContent('Something went wrong!')
        }
      }
    
      useEffect(() => {
        getHtmlcontent();
      }, []);

  return(
    <div dangerouslySetInnerHTML={{ __html: pageContent }} />
  )
}

export default HtmlPage