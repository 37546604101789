import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Selection from '../../../../components/Form/Selection';
import { Button } from '@mui/material';
import useCompanies from '../../../../hooks/useCompanies'
import AlertMessage from '../../../../components/AlertMessage';

const AdminUserForm = ({ user, setUser, handleSubmit }) => {
  const { getAllCompanies } = useCompanies();
  const [companiesList, setCompaniesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)

  const statusOptions = [{ value: 0, label: "Inactive" }, { value: 1, label: "Active" }]
  const companyOptions = companiesList.map(row => ({ value: row.id, label: row.name }));

  const getCompanies = async () => {
    const response = await getAllCompanies();
    if (response.status < 300) {
      setCompaniesList(response.companies)
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setUser({
      ...user, [name]: value
    });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <main className='templateForm'>
      <AlertMessage errorMessage={errorMessage} />
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='first_name'
          label='First Name'
          onChange={handleChange}
          value={user.first_name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='last_name'
          label='Last Name'
          onChange={handleChange}
          value={user.last_name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='email'
          name='email'
          label='Email'
          onChange={handleChange}
          value={user.email}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='password'
          name='password'
          label='Password'
          onChange={handleChange}
          value={user.password}
          required={true}
          autoComplete='off'
          style={{ width: '100%' }}
        />
        <Selection handleChange={handleChange} options={companyOptions} name={"company_id"} attributes={user} label={"Select Company"} />
        <Selection handleChange={handleChange} options={statusOptions} name={"status"} attributes={user} label={"Select Status"} />
        <TextField
          type='text'
          name='role'
          label='Role'
          onChange={handleChange}
          value={user.role}
          required={true}
          autoComplete='off'
          style={{ width: '100%', marginTop: '2rem' }}
          disabled={true}
          focused
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default AdminUserForm;