import axios from 'axios';
import { API_KEY, USER_STORAGE_KEY, ADMIN_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useWebPages = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createWebPage = async webPage => {
    const params = {
      webPage: {
        title: webPage.title,
        url: webPage.url,
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/webpages`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllWebPages = async () => {
    debugger
    const response = await axios.get(
      `${API_KEY}/users/web_pages`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteWebPage = async id => {
    const response = await axios.delete(
      `${API_KEY}/users/webpages/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateWebPage = async webPage => {
    const params = {
      webPage: {
        id: webPage.id,
        title: webPage.title,
        url: webPage.url,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users/webpages/${params.webPage.id}`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getWebPageById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/users/webpages/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getHtmlContent = async (id, token) => {
    const response = await axios.get(
      `${API_KEY}/pages/${id}/${token}`
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  return {
    createWebPage, getAllWebPages, deleteWebPage, updateWebPage, getWebPageById, getHtmlContent
  };
};
export default useWebPages;
