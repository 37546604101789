import { Paper } from '@mui/material';
import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

const SSO = () => {
  const navigate = useNavigate();
  return (
    <AdminPage>
      <main className='sso'>
        <header className='sso__headerContainer'>
          <div className='sso__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='sso__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='sso__headerContainer__header__heading'>SSO</h3>
          </div>
        </header>
        <Paper className='sso__box'>
        </Paper>
      </main>
    </AdminPage>
  );
}

export default SSO;
