import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UserEndpointGroupsTable from './Components/UserEndpointGroupsTable';

const UserEndpointGroups = () => {
  const navigate = useNavigate();
  return (
    <UserPage>
      <main className='endpointGroup'>
        <header className='endpointGroup__headerContainer'>
          <div className='endpointGroup__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='endpointGroup__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpointGroup__headerContainer__header__heading'>Endpoint Group</h3>
          </div>
          <div className='endpointGroup__headerContainer__submitButton'>
            <Button onClick={() => navigate("/adduserendpointgroup")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Endpoint Group</Button>
          </div>
        </header>
        <section className='endpointGroup__box'>
          <UserEndpointGroupsTable />
        </section>
      </main>
    </UserPage>
  );
}

export default UserEndpointGroups;
