import React, { useState } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import useUsers from '../../../../hooks/useUsers';
import UserForm from '../Components/UserForm';
import AlertMessage from '../../../../components/AlertMessage';

const AddUser = () => {
  const { createUser } = useUsers();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    status: "",
    role: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      ...user,
    };
    const response = await createUser(data);
    if (response.status < 300) {
      navigate("/users");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='users'>
        <header className='users__headerContainer'>
          <div className='users__headerContainer__header'>
            <IconButton onClick={() => navigate("/users")} className='users__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='users__headerContainer__header__heading'>Add User</h3>
          </div>
        </header>
        <section className='users__box'>
          <UserForm user={user} setUser={setUser} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default AddUser;