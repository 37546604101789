import { Button } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import useEndpoints from '../../../../hooks/useEndpoints';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import useTemplate from '../../../../hooks/useTemplate';
import AlertMessage from '../../../../components/AlertMessage';

const EndpointsTab = ({ onChange, prev, notifications, template_id, setSendNotification, endpointsList, setEndpointsList, handleSubmit }) => {
  const { getAllUserEndpoints } = useEndpoints();
  const { getAllTemplate } = useTemplate();
  const [templateList, setTemplateList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)

  const getEndpoints = async () => {
    const response = await getAllUserEndpoints();
    if (response.status < 300) {
      setEndpointsList(response.endpoints);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }
  const getTemplates = async () => {
    const response = await getAllTemplate();
    if (response.status < 300) {
      setTemplateList(response.templates);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  const endpoints = useMemo(() =>
    endpointsList.filter(rows => rows.endpoint_group_id == notifications.endpoint_groups_id.find((id) => id == rows.endpoint_group_id) && rows.location_id == notifications.locations_id.find((id) => id == rows.location_id)), [notifications, endpointsList]);

  const endpoint_ids = endpoints.map((row) => row.id)

  const template = templateList.find((item) => item.id == template_id);

  const notificationData = () => {
    let data = {
      template_id: template_id,
      endpoint_ids: endpoint_ids
    }
    setSendNotification({ ...data });
  }

  useEffect(() => {
    getEndpoints();
    getTemplates();
  }, []);

  useEffect(() => {
    notificationData();
  }, [endpointsList])

  return (
    <main className='endpointsTab'>
      <AlertMessage errorMessage={errorMessage} />
      <h1>Endpoints</h1>
      {endpoints.map((row) => (
        <div className='endpointsTab__endpointBox' key={row.id} style={{ backgroundColor: `${template?.background_color}`, color: `${template?.font_color}` }}>
          <span className='endpointsTab__endpointBox__name'><b>Endpoint Name:</b>{row?.name}</span>
          <span className='endpointsTab__endpointBox__location'><b>Location:</b>{row?.location?.name}</span>
          <span className='endpointsTab__endpointBox__device'><b>Device:</b>{row?.endpoint_group?.name}</span>
        </div>
      ))}
      <div className='endpointsTab__buttons'>
        <Button className='endpointsTab__buttons__prev' variant='contained' onClick={(e) => onChange(e, prev)}><SkipPreviousIcon sx={{ fontSize: '1rem', marginTop: '-.1rem' }} />Prev</Button>
        <Button type='submit' onClick={handleSubmit} className='endpointsTab__buttons__submit' variant='contained' disabled={endpoints.length > 0 ? false : true} >Submit</Button>
      </div>
    </main>
  );
}

export default EndpointsTab;
