import axios from 'axios';
import { API_KEY, USER_STORAGE_KEY, ADMIN_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useWebSignages = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createWebSignage = async web_signage => {
    const params = {
      web_signage: {
        name: web_signage.name,
        scroller_speed: web_signage.scroller_speed,
        landscape_title_width: web_signage.landscape_title_width,
        landscape_title_height: web_signage.landscape_title_height,
        landscape_title_top: web_signage.landscape_title_top,
        landscape_title_left: web_signage.landscape_title_left,
        landscape_description_width: web_signage.landscape_description_width,
        landscape_description_height: web_signage.landscape_description_height,
        landscape_description_top: web_signage.landscape_description_top,
        landscape_description_left: web_signage.landscape_description_left,
        potrait_title_width: web_signage.potrait_title_width,
        potrait_title_height: web_signage.potrait_title_height,
        potrait_title_top: web_signage.potrait_title_top,
        potrait_title_left: web_signage.potrait_title_left,
        potrait_description_width: web_signage.potrait_description_width,
        potrait_description_height: web_signage.potrait_description_height,
        potrait_description_top: web_signage.potrait_description_top,
        potrait_description_left: web_signage.potrait_description_left,
      }
    }
    const response = await axios.post(
      `${API_KEY}/admins/web_signages`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllWebSignages = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/web_signages`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteWebSignage = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/web_signages/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateWebSignage = async web_signage => {
    const params = {
      web_signage: {
        id: web_signage.id,
        name: web_signage.name,
        scroller_speed: web_signage.scroller_speed,
        landscape_title_width: web_signage.landscape_title_width,
        landscape_title_height: web_signage.landscape_title_height,
        landscape_title_top: web_signage.landscape_title_top,
        landscape_title_left: web_signage.landscape_title_left,
        landscape_description_width: web_signage.landscape_description_width,
        landscape_description_height: web_signage.landscape_description_height,
        landscape_description_top: web_signage.landscape_description_top,
        landscape_description_left: web_signage.landscape_description_left,
        potrait_title_width: web_signage.potrait_title_width,
        potrait_title_height: web_signage.potrait_title_height,
        potrait_title_top: web_signage.potrait_title_top,
        potrait_title_left: web_signage.potrait_title_left,
        potrait_description_width: web_signage.potrait_description_width,
        potrait_description_height: web_signage.potrait_description_height,
        potrait_description_top: web_signage.potrait_description_top,
        potrait_description_left: web_signage.potrait_description_left,
      }
    }
    const response = await axios.put(
      `${API_KEY}/admins/web_signages/${params.web_signage.id}`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getWebSignageById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/web_signages/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  const createUserWebSignage = async web_signage => {
    const params = {
      web_signage: {
        name: web_signage.name,
        scroller_speed: web_signage.scroller_speed,
        landscape_title_width: web_signage.landscape_title_width,
        landscape_title_height: web_signage.landscape_title_height,
        landscape_title_top: web_signage.landscape_title_top,
        landscape_title_left: web_signage.landscape_title_left,
        landscape_description_width: web_signage.landscape_description_width,
        landscape_description_height: web_signage.landscape_description_height,
        landscape_description_top: web_signage.landscape_description_top,
        landscape_description_left: web_signage.landscape_description_left,
        potrait_title_width: web_signage.potrait_title_width,
        potrait_title_height: web_signage.potrait_title_height,
        potrait_title_top: web_signage.potrait_title_top,
        potrait_title_left: web_signage.potrait_title_left,
        potrait_description_width: web_signage.potrait_description_width,
        potrait_description_height: web_signage.potrait_description_height,
        potrait_description_top: web_signage.potrait_description_top,
        potrait_description_left: web_signage.potrait_description_left,
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/web_signages`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllUserWebSignages = async () => {
    const response = await axios.get(
      `${API_KEY}/users/web_signages`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteUserWebSignage = async id => {
    const response = await axios.delete(
      `${API_KEY}/users/web_signages/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateUserWebSignage = async web_signage => {
    const params = {
      web_signage: {
        id: web_signage.id,
        name: web_signage.name,
        scroller_speed: web_signage.scroller_speed,
        landscape_title_width: web_signage.landscape_title_width,
        landscape_title_height: web_signage.landscape_title_height,
        landscape_title_top: web_signage.landscape_title_top,
        landscape_title_left: web_signage.landscape_title_left,
        landscape_description_width: web_signage.landscape_description_width,
        landscape_description_height: web_signage.landscape_description_height,
        landscape_description_top: web_signage.landscape_description_top,
        landscape_description_left: web_signage.landscape_description_left,
        potrait_title_width: web_signage.potrait_title_width,
        potrait_title_height: web_signage.potrait_title_height,
        potrait_title_top: web_signage.potrait_title_top,
        potrait_title_left: web_signage.potrait_title_left,
        potrait_description_width: web_signage.potrait_description_width,
        potrait_description_height: web_signage.potrait_description_height,
        potrait_description_top: web_signage.potrait_description_top,
        potrait_description_left: web_signage.potrait_description_left,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users/web_signages/${params.web_signage.id}`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getUserWebSignageById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/users/web_signages/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  return {
    createWebSignage, getAllWebSignages, deleteWebSignage, updateWebSignage, getWebSignageById, createUserWebSignage, getAllUserWebSignages, deleteUserWebSignage, updateUserWebSignage, getUserWebSignageById
  };
};

export default useWebSignages;
