import axios from 'axios';
import { API_KEY, ADMIN_STORAGE_KEY } from '../config/helpers/variables';
import useHandleErrors from './useHandleErrors';

const useICMobile = () => {
  const { handleErrors } = useHandleErrors();
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createICMobile = async icMobile => {
    const params = {
      ic_mobile: {
        name: icMobile.name,
      }
    }
    const response = await axios.post(
      `${API_KEY}/admins/ic_mobiles`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllICMobiles = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/ic_mobiles`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteICMobile = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/ic_mobiles/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateICMobile = async icMobile => {
    const params = {
      ic_mobile: {
        id: icMobile.id,
        name: icMobile.name,
      }
    }
    const response = await axios.put(
      `${API_KEY}/admins/ic_mobiles/${params.ic_mobile.id}`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getICMobileById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/ic_mobiles/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };



  return {
    createICMobile, getAllICMobiles, deleteICMobile, updateICMobile, getICMobileById
  };
};

export default useICMobile;
