import React, { useState, useEffect } from "react";
import CheckoutForm from "./Components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { STRIPE_PUBLISHABLE_KEY } from "../../../config/helpers/variables";
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

const stripePromise = loadStripe(`${STRIPE_PUBLISHABLE_KEY}`);

const PaymentPage = () => {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const { id } = useParams()
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    setClientSecret(id);
  }, [])
  return (
    <UserPage>
      <main className='paymentScreen'>
        <header className='paymentScreen__headerContainer'>
          <div className='paymentScreen__headerContainer__header'>
            <IconButton onClick={() => navigate("/packages")} className='paymentScreen__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='paymentScreen__headerContainer__header__heading'>Payment</h3>
          </div>
        </header>
        <section className='paymentScreen__box'>
          {clientSecret &&
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          }
        </section>
      </main>
    </UserPage>
  );
};

export default PaymentPage;