import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UserEndpointsTable from './Components/UserEndpointsTable';

const UserEndpoints = () => {
  const navigate = useNavigate();

  return (
    <UserPage>
      <main className='endpoint'>
        <header className='endpoint__headerContainer'>
          <div className='endpoint__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='endpoint__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpoint__headerContainer__header__heading'>Endpoints</h3>
          </div>
          <div className='endpoint__headerContainer__submitButton'>
            <Button onClick={() => navigate("/adduserendpoint")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Endpoint</Button>
          </div>
        </header>
        <section className='endpoint__box'>
          <UserEndpointsTable />
        </section>
      </main>
    </UserPage>
  );
}

export default UserEndpoints;
