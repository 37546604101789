import React from "react";
import UserNav from "./UserComponents/UserNav";

const UserPage = ({ children }) => {
  return (
    <div className='page'>
      <UserNav />

      {children}
    </div>
  );
};

export default UserPage;
