import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useWebSignages from '../../../../hooks/useWebSignages';
import AlertMessage from '../../../../components/AlertMessage';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function WebSignageSelect({ value, onChange, name }) {
  const { getAllWebSignages } = useWebSignages();
  const [signagesList, setSignagesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)

  const getSignages = async () => {
    const response = await getAllWebSignages();
    if (response.status < 300) {
      setSignagesList(response.web_signages);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getSignages();
  }, []);

  return (
    <div>
      <AlertMessage errorMessage={errorMessage} />
      <FormControl sx={{ mt: 4, width: '100%' }}>
        <InputLabel id="demo-multiple-name-label">Web Signage Id</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          // multiple
          name={name}
          value={value}
          onChange={onChange}
          input={<OutlinedInput label="1111111111111111111111" />}
          MenuProps={MenuProps}
        >
          {signagesList.map((row) => (
            <MenuItem
              key={row.id}
              value={row.id}
            >
              {row.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}