import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import useCompanies from '../../../../hooks/useCompanies';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';

export default function UsersListTable() {
  const { id } = useParams();
  const { getAllCompanyUser } = useCompanies();
  const [companyUsersList, setCompanyUsersList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [companyUsersData, setCompanyUsersData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? companyUsersList.filter((item) => item?.first_name.toLowerCase().includes(value.toLowerCase()) || item?.last_name.toLowerCase().includes(value.toLowerCase()) || item?.email.toLowerCase().includes(value.toLowerCase()))
        : companyUsersList
    setCompanyUsersData(searchFilter)
  }


  const getCompanyUsers = async () => {
    const response = await getAllCompanyUser(id);
    if (response.status < 300) {
      setCompanyUsersList(response.company_users);
      setCompanyUsersData(response.company_users);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getCompanyUsers();
  }, []);


  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead'>Id</TableCell>
              <TableCell className='table__tableHead'>First Name</TableCell>
              <TableCell className='table__tableHead'>Last Name</TableCell>
              <TableCell className='table__tableHead'>Email</TableCell>
              <TableCell className='table__tableHead'>Company</TableCell>
              <TableCell className='table__tableHead'>Role</TableCell>
              <TableCell className='table__tableHead'>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyUsersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row?.id}
                </TableCell>
                <TableCell className='table__tableRow' align="left">{row?.first_name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.last_name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.email}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.company?.name}</TableCell>
                <TableCell className='table__tableRow' align="left" style={{ textTransform: 'capitalize' }}>{row?.role}</TableCell>
                <TableCell className='table__tableRow' align="left" style={{ textTransform: 'capitalize' }}>{row?.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={companyUsersData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
