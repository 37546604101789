import React, { useState } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import UserEndpointForm from '../Components/UserEndpointForm';
import useEndpoints from '../../../../hooks/useEndpoints';
import AlertMessage from '../../../../components/AlertMessage';

const AddUserEndpoint = () => {
  const { createUserEndpoint } = useEndpoints();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [endpoint, setEndpoint] = useState({
    name: "",
    description: "",
    location_id: "",
    endpoint_group_id: "",
    device_type: "",
    device_identifier: "",
    build_no: "",
    os_version: "",
    creator_id: "",
    destination: {
      destination_type: "",
      resource_url: "",
      network_distribution_id: "",
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await createUserEndpoint(endpoint);
    if (response.status < 300) {
      navigate("/userendpoints");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='endpoint'>
        <header className='endpoint__headerContainer'>
          <div className='endpoint__headerContainer__header'>
            <IconButton onClick={() => navigate("/userendpoints")} className='endpoint__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpoint__headerContainer__header__heading'>Add Endpoint</h3>
          </div>
        </header>
        <section className='endpoint__box'>
          <UserEndpointForm endpoint={endpoint} setEndpoint={setEndpoint} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default AddUserEndpoint;
