import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import IntegrationForm from '../Components/IntegrationForm';
import useIntegration from '../../../../hooks/useIntegration';
import AlertMessage from '../../../../components/AlertMessage';

const EditIntegration = () => {
  const { getIntegrationById, updateIntegration } = useIntegration();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateIntegrations, setUpdateIntegrations] = useState({
    name: "",
    client_id: "",
    client_secret: "",
    base_url: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in updateIntegrations) {
      if (!updateIntegrations[prop]) return alert(`Please fill the form correctly!`);
    }
    const response = await updateIntegration(updateIntegrations);
    if (response.status < 300) {
      navigate("/admin/integration");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getIntegration = async () => {
    const response = await getIntegrationById(id);
    if (response.status < 300) {
      setUpdateIntegrations(response.integration);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getIntegration();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='integration'>
        <header className='integration__headerContainer'>
          <div className='integration__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/integration")} className='integration__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='integration__headerContainer__header__heading'>Edit Integration</h3>
          </div>
        </header>
        <section className='integration__box'>
          <IntegrationForm integration={updateIntegrations} setIntegration={setUpdateIntegrations} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditIntegration;
