import axios from 'axios';
import { API_KEY, USER_STORAGE_KEY } from '../config/helpers/variables';
import useHandleErrors from './useHandleErrors';

const useDistributionList = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const createDistributionList = async distributionList => {
    const params = {
      distribution_list: {
        name: distributionList.name,
        is_subscribable: distributionList.is_subscribable,
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/distribution_lists`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllDistributionLists = async () => {
    const response = await axios.get(
      `${API_KEY}/users/distribution_lists`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteDistributionList = async id => {
    const response = await axios.delete(
      `${API_KEY}/users/distribution_lists/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateDistributionList = async distributionList => {
    const params = {
      distribution_list: {
        name: distributionList.name,
        is_subscribable: distributionList.is_subscribable,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users/distribution_lists/${distributionList.id}`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getDistributionListById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/users/distribution_lists/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const subscribeDistributionList = async id => {
    const params = {
      subscription_params: {
        id: id
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/distribution_lists/${id}/subscribe_to_list`,
      { ...params },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const unSubscribeDistributionList = async id => {
    const params = {
      un_subscription_params: {
        id: id
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/distribution_lists/${id}/unsubscribe_from_list`,
      { ...params },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  return {
    createDistributionList, getAllDistributionLists, deleteDistributionList, updateDistributionList, getDistributionListById,
    subscribeDistributionList, unSubscribeDistributionList,
  };
};
export default useDistributionList;
