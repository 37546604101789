import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import LocationForm from '../Components/LocationForm';
import useLocations from '../../../../hooks/useLocations';
import AlertMessage from '../../../../components/AlertMessage';

const EditLocation = () => {
  const { getLocationById, updateLocation } = useLocations();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [updateLocations, setUpdateLocations] = useState({
    name: "",
    description: "",
    web_signage_id: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateLocation(updateLocations);
    if (response.status < 300) {
      navigate("/admin/location");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getLocations = async () => {
    const response = await getLocationById(id);
    if (response.status < 300) {
      setUpdateLocations(response.location);
    }
  }

  useEffect(() => {
    getLocations();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='location'>
        <header className='location__headerContainer'>
          <div className='location__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/location")} className='location__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='location__headerContainer__header__heading'>Edit Location</h3>
          </div>
        </header>
        <section className='location__box'>
          <LocationForm location={updateLocations} setLocation={setUpdateLocations} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditLocation;
