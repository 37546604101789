import React, { useState, useEffect } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import UserEndpointGroupForm from '../Components/UserEndpointGroupForm';
import useEndpointGroups from '../../../../hooks/useEndpointGroups';
import AlertMessage from '../../../../components/AlertMessage';

const EditUserEndpointGroup = () => {
  const { getUserEndpointGroupById, updateUserEndpointGroup } = useEndpointGroups();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateEndpointGroups, setUpdateEndpointGroups] = useState({
    name: "",
    description: "",
    endpoint_type: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in updateEndpointGroups) {
      if (!updateEndpointGroups[prop]) return alert(`Please fill the form correctly!`);
    }
    const response = await updateUserEndpointGroup(updateEndpointGroups);
    if (response.status < 300) {
      navigate("/userendpointgroups");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getEndpointGroup = async () => {
    const response = await getUserEndpointGroupById(id);
    if (response.status < 300) {
      setUpdateEndpointGroups(response.endpoint_group);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }
  useEffect(() => {
    getEndpointGroup();
  }, []);


  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='endpointGroup'>
        <header className='endpointGroup__headerContainer'>
          <div className='endpointGroup__headerContainer__header'>
            <IconButton onClick={() => navigate("/userendpointgroups")} className='endpointGroup__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpointGroup__headerContainer__header__heading'>Edit Endpoint Group</h3>
          </div>
        </header>
        <section className='endpointGroup__box'>
          <UserEndpointGroupForm endpointGroup={updateEndpointGroups} setEndpointGroup={setUpdateEndpointGroups} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default EditUserEndpointGroup;
