import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import WebPagesTable from './Components/WebPagesTable';

const WebPages = () => {
  const navigate = useNavigate();
  return (
    <UserPage>
      <main className='webPages'>
        <header className='webPages__headerContainer'>
          <div className='webPages__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='webPages__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='webPages__headerContainer__header__heading'>Web Pages</h3>
          </div>
        </header>
        <section className='webPages__box'>
          <WebPagesTable />
        </section>
      </main>
    </UserPage>
  );
}

export default WebPages;
