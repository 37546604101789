import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { THEME_COLOR } from "../../../config/helpers/variables";
import AdminSidebarRoutes from './AdminSideBarRoutes';
import Settings from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import MonitorIcon from '@mui/icons-material/Monitor';
import { Link } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { ADMIN_STORAGE_KEY } from "../../../config/helpers/variables";
import InventoryIcon from '@mui/icons-material/Inventory';
export default function AdminSidebar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const configurationOptions = [{ name: 'Web Singnage', path: '/admin/websignage' }, { name: 'Location', path: '/admin/location' }, { name: 'Endpoint Group', path: '/admin/endpointgroup' }, { name: 'Endpoint', path: '/admin/endpoint' }, { name: 'Integration', path: '/admin/integration' }, { name: 'IC Mobile', path: '/admin/icmobile' }, { name: 'System', path: '/admin/system' }]

  const monitorOptions = [{ name: 'Logs', path: '/admin/logs' }, { name: 'Alerts', path: '/admin/alerts' }, { name: 'Notification history', path: '/admin/notificationhistory' }]


  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const list = anchor => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      style={{ backgroundColor: THEME_COLOR, minHeight: "980px" }}
      role='presentation'
    >
      <div className='sidebar__list'>
        <img
          src='https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
          alt='' className='sidebar__list__profileImage' />
        <h5 className='sidebar__list__userName'>{admin?.data?.name}</h5>
        <span className='sidebar__list__email'>{admin?.data?.email}</span>
        <div className='sidebar__list__dashboard'>
          <Link to="/admin/dashboard" className='sidebar__list__dashboard__dashboardLink'><DashboardIcon sx={{ marginRight: "2rem" }} />Dashboard</Link>
          <Link to="/admin/companies" className='sidebar__list__dashboard__dashboardLink'><ApartmentIcon sx={{ marginRight: "2rem" }} />Company</Link>
          <Link to="/admin/users" className='sidebar__list__dashboard__dashboardLink'><GroupIcon sx={{ marginRight: "2rem" }} />Users</Link>
          <Link to="/admin/packages" className='sidebar__list__dashboard__dashboardLink'><InventoryIcon sx={{ marginRight: "2rem" }} />Packages</Link>
          <AdminSidebarRoutes options={configurationOptions} icon={<Settings />} title={"Configuration"} />
          <AdminSidebarRoutes options={monitorOptions} icon={<MonitorIcon />} title={"Monitor"} />
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      {["left"].map(anchor => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} style={{ color: "rgba(255, 255, 255, 0.8)", marginLeft: "-.5rem" }}>
            <MenuIcon />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
