import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DistributionListDeleteModal from './DistributionListDeleteModal';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';
import useDistributionList from '../../../../hooks/useDistributionList'
import { USER_STORAGE_KEY } from '../../../../config/helpers/variables';
import SuccessMessage from '../../../../components/SuccessMessage';
import ViewDistributionListSubscriptionModal from './ViewDistributionListSubscriptionModal';
import ViewDistributionListUnSubscriptionModal from './ViewDistributionListUnSubscriptionModal';

export default function DistributionListTable() {
  const navigate = useNavigate();
  const { getAllDistributionLists, subscribeDistributionList, unSubscribeDistributionList } = useDistributionList();
  const [distributionList, setDistributionList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [distributionListData, setDistributionListData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  // const handleSubscribe = async (e, id) => {
  //   e.preventDefault();
  //   const response = await subscribeDistributionList(id);
  //   if (response.status === 200) {
  //     setSuccessMessage(response.message);
  //   } else if (response.status !== 200) {
  //     setErrorMessage(response.message);
  //   }
  // }

  // const handleUnSubscribe = async (e, id) => {
  //   e.preventDefault();
  //   const response = await unSubscribeDistributionList(id);
  //   if (response.status === 200) {
  //     setErrorMessage(response.message);
  //   } else if (response.status !== 200) {
  //     setErrorMessage(response.message);
  //   }
  // }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? distributionList.filter((item) => item?.name.toLowerCase().includes(value.toLowerCase()))
        : distributionList
    setDistributionListData(searchFilter)
  }


  const getDistributionList = async () => {
    const response = await getAllDistributionLists();
    if (response.status < 300) {
      setDistributionList(response.distribution_lists);
      setDistributionListData(response.distribution_lists);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getDistributionList();
  }, []);

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <SuccessMessage successMessage={successMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead'>ID</TableCell>
              <TableCell className='table__tableHead' align="left">Name</TableCell>
              <TableCell className='table__tableHead' align="left">Subscribable</TableCell>
              <TableCell className='table__tableHead' align="left">Manage Users Subscription</TableCell>
              {user?.data?.role === "Administrator" &&
                <TableCell className='table__tableHead' align="left">Actions</TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {distributionListData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row?.id}
                </TableCell>
                <TableCell className='table__tableRow' align="left">{row?.name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.is_subscribable ? "Yes" : "No"}</TableCell>
                <TableCell className='table__tableRow' align="left">
                  {row?.is_subscribable &&
                    < div style={{ display: "flex" }}>
                      <div style={{ marginRight: "1rem" }}>
                        <ViewDistributionListSubscriptionModal id={row?.id} />
                      </div>
                      <div>
                        <ViewDistributionListUnSubscriptionModal id={row?.id} />
                      </div>
                    </div>
                  }
                </TableCell>
                {user?.data?.role === "Administrator" &&
                  <TableCell className='table__tableRow' style={{ display: "flex", justifyContent: "left" }}>
                    {!(row?.creator_type === "default") ?
                      <>
                        <IconButton onClick={() => navigate(`/distribution_list/${row.id}/edit`)}>
                          <BorderColorIcon sx={{ color: "blue", fontSize: "1.2rem" }} />
                        </IconButton>
                        <DistributionListDeleteModal id={row?.id} distributionListData={distributionListData} setDistributionListData={setDistributionListData} />
                      </>
                      :
                      <p style={{ lineHeight: "22px", color: "lightgray" }}><small>System Distribution List!</small></p>
                    }
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={distributionListData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
