import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { THEME_COLOR } from "../../../config/helpers/variables";
import MonitorIcon from '@mui/icons-material/Monitor';
import SidebarRoutes from './SideBarRoutes';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link } from "react-router-dom";
import Settings from '@mui/icons-material/Settings';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { USER_STORAGE_KEY } from "../../../config/helpers/variables";
export default function Sidebar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const configurationOptions = [{ name: 'Web Singnage', path: '/userwebsignages' }, { name: 'Location', path: '/userlocations' }, { name: 'Endpoint Group', path: '/userendpointgroups' }, { name: 'Endpoint', path: '/userendpoints' }, { name: 'Distribution List', path: '/distributionList' }, { name: 'Integration', path: '/user/integration' }, { name: 'Web Pages', path: '/webpages' }]

  const userMonitorOptions = [{ name: "Notification history", path: "/notificationhistory" }]

  const list = anchor => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      style={{ backgroundColor: THEME_COLOR, minHeight: "800px" }}
      role='presentation'
    >
      <div className='sidebar__list'>
        <img
          src='https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
          alt='' className='sidebar__list__profileImage' />
        <h5 className='sidebar__list__userName'>{user?.data?.first_name} {user?.data?.last_name}</h5>
        <span className='sidebar__list__email'>{user?.data?.email}</span>
        <div className='sidebar__list__dashboard'>
          <Link to="/dashboard" className='sidebar__list__dashboard__dashboardLink'><DashboardIcon sx={{ marginRight: "2rem" }} />Dashboard</Link>
          <Link to="/users" className='sidebar__list__dashboard__dashboardLink'><GroupOutlinedIcon sx={{ marginRight: "2rem" }} />Users</Link>
          {(user?.data?.role == "Super User" || user?.data?.role == "Administrator") &&
            <SidebarRoutes options={configurationOptions} icon={<Settings />} title={"Configuration"} />
          }
          <SidebarRoutes options={userMonitorOptions} icon={<MonitorIcon />} title={"Monitor"} />
        </div>
      </div>
    </Box>
  );
  return (
    <div>
      {["left"].map(anchor => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} >
            <MenuIcon style={{ color: "rgba(255, 255, 255, 0.8)", marginLeft: "-.5rem" }} />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
