import React, { useState } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import useIntegration from '../../../../hooks/useIntegration';
import IntegrationForm from '../Components/IntegrationForm';
import AlertMessage from '../../../../components/AlertMessage';

const AddUserIntegration = () => {
  const { createUserIntegration } = useIntegration();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [integration, setIntegration] = useState({
    name: null,
    ic_mobile_permanent_token: "",
    client_id: "",
    client_secret: "",
    base_url: "",
    username: "",
    password: ""
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await createUserIntegration(integration);
    if (response.status < 300) {
      navigate("/user/integration");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='integration'>
        <header className='integration__headerContainer'>
          <div className='integration__headerContainer__header'>
            <IconButton onClick={() => navigate("/user/integration")} className='integration__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='integration__headerContainer__header__heading'>Add Integration</h3>
          </div>
        </header>
        <section className='integration__box'>
          <IntegrationForm integration={integration} setIntegration={setIntegration} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default AddUserIntegration;
