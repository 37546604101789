import React from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

const IntegrationForm = ({ integration, setIntegration, handleSubmit }) => {

  const handleChange = e => {
    const { name, value } = e.target;
    setIntegration({
      ...integration, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={integration.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='client_id'
          label='Client Id'
          onChange={handleChange}
          value={integration.client_id}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='client_secret'
          label='Client Secret'
          onChange={handleChange}
          value={integration.client_secret}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='base_url'
          label='Base Url'
          onChange={handleChange}
          value={integration.base_url}
          required={true}
          style={{ width: '100%' }}
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default IntegrationForm;
