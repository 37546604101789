import React, { useState } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AdminPackageForm from '../Components/AdminPackageForm';
import usePackages from '../../../../hooks/usePackages';
import AlertMessage from '../../../../components/AlertMessage';

const AddAdminPackage = () => {
  const { createAdminPackage } = usePackages();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [adminPackages, setAdminPackages] = useState({
    name: "",
    price: "",
    duration: "",
    users_creating_limit: "",
    locations_creating_limit: "",
    endpoints_creating_limit: "",
    endpoint_groups_creating_limit: "",
    promotion: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    // for (let prop in adminPackages) {
    //   if (!adminPackages[prop]) return alert(`Please fill the form correctly!`);
    // }
    const response = await createAdminPackage(adminPackages);
    if (response.status < 300) {
      navigate("/admin/packages");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='adminPackages'>
        <header className='adminPackages__headerContainer'>
          <div className='adminPackages__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/packages")} className='adminPackages__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='adminPackages__headerContainer__header__heading'>Add Package</h3>
          </div>
        </header>
        <section className='adminPackages__box'>
          <AdminPackageForm adminPackages={adminPackages} setAdminPackages={setAdminPackages} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AddAdminPackage;
