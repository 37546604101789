import React from 'react';
import TextField from '@mui/material/TextField';
import UserWebSignageSelect from './UserWebSignageSelect';
import { Button } from '@mui/material';

const UserLocationForm = ({ location, setLocation, handleSubmit }) => {

  const handleChange = e => {
    const { name, value } = e.target;
    setLocation({
      ...location, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={location.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          multiline
          maxRows={4}
          type='text'
          name='description'
          label='Description'
          onChange={handleChange}
          value={location.description}
          required={true}
          style={{ width: '100%' }}
        />
        <UserWebSignageSelect onChange={handleChange} value={location.web_signage_id} name={"web_signage_id"} />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default UserLocationForm;
