import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import UserLocationsTable from './Components/UserLocationsTable';
import AddIcon from '@mui/icons-material/Add';

const UserLocations = () => {
  const navigate = useNavigate();

  return (
    <UserPage>
      <main className='location'>
        <header className='location__headerContainer'>
          <div className='location__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='location__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='location__headerContainer__header__heading'>Location</h3>
          </div>
          <div className='location__headerContainer__submitButton'>
            <Button onClick={() => navigate("/adduserlocation")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Location</Button>
          </div>
        </header>
        <section className='location__box'>
          <UserLocationsTable />
        </section>
      </main>
    </UserPage>
  );
}

export default UserLocations;
