import React, { useState } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import EndpointForm from '../Components/EndpointForm';
import useEndpoints from '../../../../hooks/useEndpoints';
import AlertMessage from '../../../../components/AlertMessage';

const AddEndpoint = () => {
  const { createEndpoint } = useEndpoints();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [endpoint, setEndpoint] = useState({
    name: "",
    description: "",
    location_id: "",
    endpoint_group_id: "",
    device_type: "",
    device_identifier: "",
    build_no: "",
    os: "",
    destination: {
      destination_type: "",
      resource_url: "",
      network_distribution_id: "",
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in endpoint) {
      if (!endpoint[prop]) return alert(`Please fill the form correctly!`);
    }
    const response = await createEndpoint(endpoint);
    if (response.status < 300) {
      navigate("/admin/endpoint");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }

  }

  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='endpoint'>
        <header className='endpoint__headerContainer'>
          <div className='endpoint__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/endpoint")} className='endpoint__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpoint__headerContainer__header__heading'>Add Endpoint</h3>
          </div>
        </header>
        <section className='endpoint__box'>
          <EndpointForm endpoint={endpoint} setEndpoint={setEndpoint} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AddEndpoint;
