import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import useAuthenticate from '../../../hooks/useAuthenticate';
import { ADMIN_STORAGE_KEY } from '../../../config/helpers/variables';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    backgroundColor: "white",
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(-1.8),
    minWidth: 85,
    maxHeight: 100,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '5',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 5,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(-7),
        minHeight: "1rem",
        padding: '0',
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function AdminLogoutMenu({ menuColor }) {
  const { adminLogout } = useAuthenticate();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const handleLogout = async () => {
    const response = await adminLogout(admin);
    if (response.status == 200) {
      sessionStorage.removeItem(ADMIN_STORAGE_KEY);
    }
    navigate("/admin/signin")
  }


  return (
    <div>
      <IconButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <ManageAccountsIcon style={{ color: "rgba(255, 255, 255, 0.8)" }} />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate("/admin/editadminprofile")} disableRipple style={{ fontSize: '.9rem', display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
          <EditIcon style={{ fontSize: "1rem", marginLeft: "0" }} />
          <span style={{ fontSize: ".9rem", marginLeft: "3.8rem", color: "black" }}>Profile</span>
        </MenuItem>
        <MenuItem disableRipple style={{ fontSize: '.9rem' }} onClick={handleLogout}>
          <LogoutIcon style={{ fontSize: "1rem", marginLeft: "0" }} />
          <span style={{ fontSize: ".9rem", marginLeft: "3.8rem", color: "black" }}>Logout</span>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
