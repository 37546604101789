import React, { useState } from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AdminTemplateForm from './Components/AdminTemplateForm';
import useTemplate from '../../../hooks/useTemplate';
import AlertMessage from '../../../components/AlertMessage';

const AdminTemplate = () => {
  const navigate = useNavigate();
  const { createTemplateAdmin } = useTemplate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [template, setTemplate] = useState({
    name: "",
    subject: "",
    body: "",
    audio: "",
    font_color: "#808080",
    background_color: "#FFFFFF",
    admin_id: 1,
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      ...template,
    };
    const response =await createTemplateAdmin(data);
    if (response.status < 300) {
      navigate("/admin/dashboard");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='template'>
        <header className='template__headerContainer'>
          <div className='template__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='template__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='template__headerContainer__header__heading'>Add Template</h3>
          </div>
        </header>
        <section className='template__box'>
          <AdminTemplateForm template={template} setTemplate={setTemplate} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AdminTemplate;
