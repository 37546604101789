import axios from "axios";
import {
  API_KEY,
  USER_STORAGE_KEY,
  ADMIN_STORAGE_KEY,
} from "../config//helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useNotifications = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createAdminNotification = async (notification) => {
    const params = {
      notification: {
        template_id: notification.template_id,
        endpoint_ids: notification.endpoint_ids,
      },
    };
    const response = await axios
      .post(
        `${API_KEY}/admins/notifications`,
        {
          ...params,
        },
        getAdminHeaders()
      )
      .then((res) => {
        if (res.data.status > 300) {
          handleErrors(res);
        }
        return res.data;
      });
    return response;
  };

  const deleteAdminNotification = async (id) => {
    const response = await axios
      .delete(`${API_KEY}/admins/notifications/${id}`, getAdminHeaders())
      .then((res) => {
        if (res.data.status > 300) {
          handleErrors(res);
        }
        return res.data;
      });
    return response;
  };

  const createNotification = async (notification) => {
    const params = {
      notification: {
        template_id: notification.template_id,
        endpoint_ids: notification.endpoint_ids,
      },
    };
    const response = await axios
      .post(
        `${API_KEY}/users/notifications`,
        {
          ...params,
        },
        getHeaders()
      )
      .then((res) => {
        if (res.data.status > 300) {
          handleErrors(res);
        }
        return res.data;
      });
    return response;
  };

  const deleteNotification = async (id) => {
    const response = await axios
      .delete(`${API_KEY}/users/notifications/${id}`, getHeaders())
      .then((res) => {
        if (res.data.status > 300) {
          handleErrors(res);
        }
        return res.data;
      });
    return response;
  };

  const getAllAdminNotifications = async () => {
    const response = await axios
      .get(`${API_KEY}/admins/notifications`, getAdminHeaders())
      .then((res) => {
        if (res.data.status > 300) {
          handleErrors(res);
        }
        return res.data;
      });
    return response;
  };

  const getAllNotifications = async () => {
    const response = await axios
      .get(`${API_KEY}/users/notifications`, getHeaders())
      .then((res) => {
        if (res.data.status > 300) {
          handleErrors(res);
        }
        return res.data;
      });
    return response;
  };

  return {
    createAdminNotification,
    createNotification,
    deleteAdminNotification,
    deleteNotification,
    getAllAdminNotifications,
    getAllNotifications,
  };
};

export default useNotifications;
