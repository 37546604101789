import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import UserPage from '../../../components/PageWrapper/UserPage';
import SubscriptionsDetails from './Components/SubscriptionsDetails';
import UserProfile from './Components/UserProfile';
import CompanyProfile from './Components/CompanyProfile';
import { USER_STORAGE_KEY } from '../../../config/helpers/variables';

const Profile = () => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  return (
    <UserPage>
      <main className='userProfile'>
        <header className='userProfile__headerContainer'>
          <div className='userProfile__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='userProfile__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='userProfile__headerContainer__header__heading'>My Profile</h3>
          </div>
          <div className='userProfile__headerContainer__submitButton'>
            <Button onClick={() => navigate("/editprofile")} >Edit Profile</Button>
          </div>
        </header>
        <section className='userProfile__box'>
          <div className='userProfile__box__profileAndSubscriptions'>
            <UserProfile user={user} />
            <SubscriptionsDetails user={user} />
          </div>
          <CompanyProfile user={user} />
        </section>
      </main>
    </UserPage>
  );
}

export default Profile;
