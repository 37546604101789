import { Paper } from '@mui/material';
import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

const Alerts = () => {
  const navigate = useNavigate();
  return (
    <AdminPage>
      <main className='alerts'>
        <header className='alerts__headerContainer'>
          <div className='alerts__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='alerts__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='alerts__headerContainer__header__heading'>Alerts</h3>
          </div>
        </header>
        <Paper className='alerts__box'>
        </Paper>
      </main>
    </AdminPage>
  );
}

export default Alerts;
