import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import AdminSidebar from "./AdminSideBar";
import { THEME_COLOR } from "../../../config/helpers/variables";
import { Link } from "react-router-dom";
import AdminLogoutMenu from "./AdminLogoutMenu";
import AdminNavMenu from "./AdminNavMenu";

const AdminNav = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const configurationOptions = [
    { name: "Web Singnage", path: "/admin/websignage" },
    { name: "Location", path: "/admin/location" },
    { name: "Endpoint Group", path: "/admin/endpointgroup" },
    { name: "Endpoint", path: "/admin/endpoint" },
    { name: "Integration", path: "/admin/integration" },
    { name: "IC Mobile", path: "/admin/icmobile" },
    { name: "System", path: "/admin/system" },
  ];

  const monitorOptions = [
    { name: "Logs", path: "/admin/logs" },
    { name: "Alerts", path: "/admin/alerts" },
    { name: "Notification history", path: "/admin/notificationhistory" },
  ];
  return (
    <AppBar position="static" style={{ backgroundColor: THEME_COLOR }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "flex", md: "flex", lg: "flex" },
              marginLeft: "-1.5rem",
              marginRight: "1rem",
            }}
          >
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              className="userSidebar userSidebarSmall"
            >
              <AdminSidebar />
            </IconButton>
            <Box className="userNavbar userNavbarSmall">
              <Link className="userNavbar__link" to="/admin/dashboard">
                <img
                  className="userNavbar__link__logo"
                  src={require("../../../assets/NTS LOGO.png")}
                />
              </Link>
              <span className="userNavbar__link">
                <AdminNavMenu
                  options={configurationOptions}
                  title={"Configuration"}
                />
              </span>
              <Link className="userNavbar__link" to="/admin/companies">
                Company
              </Link>
              <Link className="userNavbar__link" to="/admin/users">
                Users
              </Link>
              <Link className="userNavbar__link" to="/admin/packages">
                Packages
              </Link>
              <span className="userNavbar__link">
                <AdminNavMenu options={monitorOptions} title={"Monitor"} />
              </span>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            ></Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0, marginRight: "-1rem" }}>
            <AdminLogoutMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AdminNav;
