import React, { useState } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import UserWebSignageForm from '../Components/UserWebSignageForm';
import useWebSignages from '../../../../hooks/useWebSignages';
import AlertMessage from '../../../../components/AlertMessage';

const AddUserWebSignage = () => {
  const { createUserWebSignage } = useWebSignages();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [webSignage, setWebSignage] = useState({
    name: "",
    scroller_speed: "",
    landscape_title_width: "",
    landscape_title_height: "",
    landscape_title_top: "",
    landscape_title_left: "",
    landscape_description_width: "",
    landscape_description_height: "",
    landscape_description_top: "",
    landscape_description_left: "",
    potrait_title_width: "",
    potrait_title_height: "",
    potrait_title_top: "",
    potrait_title_left: "",
    potrait_description_width: "",
    potrait_description_height: "",
    potrait_description_top: "",
    potrait_description_left: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in webSignage) {
      if (!webSignage[prop]) return alert(`Please fill the form correctly!`);
    };
    const response = await createUserWebSignage(webSignage);
    if (response.status < 300) {
      navigate("/userwebsignages");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='webSignage'>
        <header className='webSignage__headerContainer'>
          <div className='webSignage__headerContainer__header'>
            <IconButton onClick={() => navigate("/userwebsignages")} className='webSignage__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='webSignage__headerContainer__header__heading'>Add Web Signage</h3>
          </div>
        </header>
        <section className='webSignage__box'>
          <UserWebSignageForm webSignage={webSignage} setWebSignage={setWebSignage} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default AddUserWebSignage;
