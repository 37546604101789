import axios from 'axios';
import { API_KEY, ADMIN_STORAGE_KEY, USER_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useIntegration = () => {
  const { handleErrors } = useHandleErrors();
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const createIntegration = async integration => {
    const params = {
      integration: {
        name: integration.name,
        client_id: integration.client_id,
        client_secret: integration.client_secret,
        base_url: integration.base_url,
      }
    }
    const response = await axios.post(
      `${API_KEY}/admins/integrations`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllIntegrations = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/integrations`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteIntegration = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/integrations/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateIntegration = async integration => {
    const params = {
      integration: {
        id: integration.id,
        name: integration.name,
        client_id: integration.client_id,
        client_secret: integration.client_secret,
        base_url: integration.base_url,
      }
    }
    const response = await axios.put(
      `${API_KEY}/admins/integrations/${params.integration.id}`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getIntegrationById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/integrations/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const createUserIntegration = async integration => {
    const params = {
      integration: {
        name: integration.name,
        client_id: integration.client_id,
        client_secret: integration.client_secret,
        base_url: integration.base_url,
        ic_mobile_permanent_token: integration.ic_mobile_permanent_token,
        username: integration.username,
        password: integration.password
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/integrations`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllUserIntegrations = async () => {
    debugger
    const response = await axios.get(
      `${API_KEY}/users/integrations`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteUserIntegration = async id => {
    const response = await axios.delete(
      `${API_KEY}/users/integrations/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateUserIntegration = async integration => {
    const params = {
      integration: {
        id: integration.id,
        name: integration.name,
        client_id: integration.client_id,
        client_secret: integration.client_secret,
        base_url: integration.base_url,
        ic_mobile_permanent_token: integration.ic_mobile_permanent_token,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users/integrations/${params.integration.id}`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getUserIntegrationById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/users/integrations/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  return {
    createIntegration, getAllIntegrations, deleteIntegration, updateIntegration, getIntegrationById, createUserIntegration, getAllUserIntegrations, deleteUserIntegration, updateUserIntegration, getUserIntegrationById,
  };
};
export default useIntegration;
