import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import TemplateCards from './Components/TemplateCards';

const Dashboard = () => {

  return (
    <UserPage>
      <main className='dashboard'>
        <header className='dashboard__header'>
          <h3 className='dashboard__header__heading'>Dashboard</h3>
        </header>
        <section className='dashboard__box'>
          <TemplateCards />
        </section>
      </main>
    </UserPage >
  );
}

export default Dashboard;
