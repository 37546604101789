import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../../../components/AlertMessage';
import usePackages from '../../../../hooks/usePackages';
import { USER_STORAGE_KEY } from '../../../../config/helpers/variables';

const PackagesCards = () => {
  const navigate = useNavigate();
  const { getPackages, buyPackage } = usePackages();
  const [packagesList, setPackagesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY))

  const getAllPackages = async () => {
    const response = await getPackages();
    if (response.status < 300) {
      setPackagesList(response.packages);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }
  const purchasePackage = async (id) => {
    const response = await buyPackage(id);
    if (response.clientSecret != "") {
      navigate(`/checkoutSubscription/${response.clientSecret}`);
    }
  }

  useEffect(() => {
    getAllPackages();
  }, []);
  return (
    <section className='packagesCards'>
      <AlertMessage errorMessage={errorMessage} />
      <header className='packagesCards__header'>
        <h1 className='packagesCards__header__heading'>Select a plan that works for you:</h1>
      </header>
      <div className='packagesCards__cards'>
        {packagesList.map((row, index) => (
          <div className='packagesCards__cards__card' key={row?.id}>
            <h1 className='packagesCards__cards__card__packageName'>{row?.name}</h1>
            <div className='packagesCards__cards__card__priceBox'>
              <h1 className='packagesCards__cards__card__priceBox__discount'>{row?.promotion}</h1>
              <h1 className='packagesCards__cards__card__priceBox__price'><sup>$</sup>{row?.price}</h1>
              <span className='packagesCards__cards__card__priceBox__billPeriod'>per month</span>
            </div>
            <Button variant='contained' onClick={() => purchasePackage(row?.id)} disabled={user?.data?.subscription?.package_id == row?.id ? true : false}>{user?.data?.subscription?.package_id == row?.id ? "Currently Active" : "Get it now"}</Button>
            <div className='packagesCards__cards__card__duration'>
              <span className='packagesCards__cards__card__duration__time'>Billed every {row?.duration}.</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default PackagesCards;
