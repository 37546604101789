import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AdminUsersTable from './Components/AdminUsersTable';

const AdminUsers = () => {
  const navigate = useNavigate();
  return (
    <AdminPage>
      <main className='users'>
        <header className='users__headerContainer'>
          <div className='users__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='users__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='users__headerContainer__header__heading'>Users</h3>
          </div>
          <div className='users__headerContainer__submitButton'>
            <Button onClick={() => navigate("/admin/adduser")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />User</Button>
          </div>

        </header>
        <section className='users__box'>
          <AdminUsersTable />
        </section>
      </main>
    </AdminPage>
  );
}

export default AdminUsers;