import React, { useState } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import LocationForm from '../Components/UserLocationForm';
import useLocations from '../../../../hooks/useLocations';
import AlertMessage from '../../../../components/AlertMessage';

const AddUserLocation = () => {
  const { createUserLocation } = useLocations();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [location, setLocation] = useState({
    name: "",
    description: "",
    web_signage_id: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in location) {
      if (!location[prop]) return alert(`Please fill the form correctly!`);
    }
    const response = await createUserLocation(location);
    if (response.status < 300) {
      navigate("/userlocations");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='location'>
        <header className='location__headerContainer'>
          <div className='location__headerContainer__header'>
            <IconButton onClick={() => navigate("/userlocations")} className='location__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='location__headerContainer__header__heading'>Add Location</h3>
          </div>
        </header>
        <section className='location__box'>
          <LocationForm location={location} setLocation={setLocation} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default AddUserLocation;
