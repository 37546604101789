import axios from 'axios';
import { API_KEY, USER_STORAGE_KEY, ADMIN_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useEndpoints = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createEndpoint = async endpoint => {
    const params = {
      endpoint: {
        name: endpoint.name,
        description: endpoint.description,
        location_id: endpoint.location_id,
        endpoint_group_id: endpoint.endpoint_group_id,
        device_type: endpoint.destination.device_type,
        device_identifier: endpoint.destination.device_identifier,
        build_no: endpoint.destination.build_no,
        os: endpoint.destination.os,
      },
      destination: {
        destination_type: endpoint.destination.destination_type,
        resource_url: endpoint.destination.resource_url,
        network_distribution_id: endpoint.destination.network_distribution_id,
      }
    }
    const response = await axios.post(
      `${API_KEY}/admins/endpoints`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllEndpoints = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/endpoints`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteEndpoint = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/endpoints/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateEndpoint = async (endpoint, id) => {
    const params = {
      endpoint: {
        name: endpoint.name,
        description: endpoint.description,
        location_id: endpoint.location_id,
        endpoint_group_id: endpoint.endpoint_group_id,
      },
      destination: {
        destination_type: endpoint.destination.destination_type,
        resource_url: endpoint.destination.resource_url,
        network_distribution_id: endpoint.destination.network_distribution_id,
      }
    }
    const response = await axios.put(
      `${API_KEY}/admins/endpoints/${id}`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getEndpointById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/endpoints/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };



  const createUserEndpoint = async endpoint => {
    debugger
    const params = {
      endpoint: {
        name: endpoint.name,
        description: endpoint.description,
        location_id: endpoint.location_id,
        endpoint_group_id: endpoint.endpoint_group_id,
        device_type: endpoint.device_type,
        device_identifier: endpoint.device_identifier,
        build_no: endpoint.build_no,
        os_version: endpoint.os_version,
        creator_id: endpoint.creator_id,
      },
      destination: {
        destination_type: endpoint.destination.destination_type,
        resource_url: endpoint.destination.resource_url,
        network_distribution_id: endpoint.destination.network_distribution_id,
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/endpoints`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllUserEndpoints = async () => {
    const response = await axios.get(
      `${API_KEY}/users/endpoints`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteUserEndpoint = async id => {
    const response = await axios.delete(
      `${API_KEY}/users/endpoints/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateUserEndpoint = async (endpoint, id) => {
    const params = {
      endpoint: {
        name: endpoint.name,
        description: endpoint.description,
        location_id: endpoint.location_id,
        endpoint_group_id: endpoint.endpoint_group_id,
        device_type: endpoint.device_type,
        device_identifier: endpoint.device_identifier,
        build_no: endpoint.build_no,
        os_version: endpoint.os_version,
        creator_id: endpoint.creator_id,
      },
      destination: {
        destination_type: endpoint.destination.destination_type,
        resource_url: endpoint.destination.resource_url,
        network_distribution_id: endpoint.destination.network_distribution_id,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users/endpoints/${id}`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getUserEndpointById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/users/endpoints/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  return {
    createEndpoint, getAllEndpoints, deleteEndpoint, updateEndpoint, getEndpointById, createUserEndpoint, getAllUserEndpoints, deleteUserEndpoint, updateUserEndpoint, getUserEndpointById,
  };
};
export default useEndpoints;
