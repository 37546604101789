import React from 'react';
import TextField from '@mui/material/TextField';
import Selection from '../../../../components/Form/Selection';
import { Button } from '@mui/material';

const UserEndpointGroupForm = ({ endpointGroup, setEndpointGroup, handleSubmit }) => {
  const endpointGroupTypeOptions = [{ value: 1, label: "Both" }, { value: 2, label: "Audio" }, { value: 3, label: "Text" }]
  const handleChange = e => {
    const { name, value } = e.target;
    setEndpointGroup({
      ...endpointGroup, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={endpointGroup.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          multiline
          maxRows={4}
          type='text'
          name='description'
          label='Description'
          onChange={handleChange}
          value={endpointGroup.description}
          required={true}
          style={{ width: '100%' }}
        />
        <Selection handleChange={handleChange} options={endpointGroupTypeOptions} name={"endpoint_type"} attributes={endpointGroup} label={"Endpoint Type"} />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default UserEndpointGroupForm;
