import React, { useState } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DistributionListForm from '../Components/DistributionListForm';
import useDistributionList from '../../../../hooks/useDistributionList';
import AlertMessage from '../../../../components/AlertMessage';

const AddDistributionList = () => {
  const { createDistributionList } = useDistributionList();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [distributionList, setDistributionList] = useState({
    name: "",
    is_subscribable: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await createDistributionList(distributionList);
    if (response.status < 300) {
      navigate("/distributionList");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='distributionList'>
        <header className='distributionList__headerContainer'>
          <div className='distributionList__headerContainer__header'>
            <IconButton onClick={() => navigate("/distributionList")} className='distributionList__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='distributionList__headerContainer__header__heading'>Add Distribution List</h3>
          </div>
        </header>
        <section className='distributionList__box'>
          <DistributionListForm distributionList={distributionList} setDistributionList={setDistributionList} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default AddDistributionList;
