import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CompanyForm from '../Components/CompanyForm';
import useCompanies from '../../../../hooks/useCompanies';
import AlertMessage from '../../../../components/AlertMessage';

const EditCompany = () => {
  const { getCompanyById, updateCompany } = useCompanies();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateCompanyDetails, setUpdateCompanyDetails] = useState({
    name: '',
    sub_domain: '',
    logo: '',
    status: '',
    okta_sso_login: '',
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      ...updateCompanyDetails,
    };
    const response = await updateCompany(data, id);
    if (response.status < 300) {
      navigate("/admin/companies");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }

  }

  const getCompany = async () => {
    const response = await getCompanyById(id);
    if (response.status < 300) {
      setUpdateCompanyDetails({ ...updateCompanyDetails, name: response.company.name, sub_domain: response.company.sub_domain, okta_sso_login: response.company.okta_sso_login, status: response.company.status, logo: response.company.logo });
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getCompany();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='companies'>
        <header className='companies__headerContainer'>
          <div className='companies__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/companies")} className='companies__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='companies__headerContainer__header__heading'>Edit Company</h3>
          </div>
        </header>
        <section className='companies__box'>
          <CompanyForm company={updateCompanyDetails} setCompany={setUpdateCompanyDetails} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditCompany;
