import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import { THEME_COLOR } from "../../../config/helpers/variables";
import { Link } from 'react-router-dom';
import Sidebar from "./SideBar";
import LogoutMenu from "./LogoutMenu";
import NavMenu from "./NavMenu";
import { USER_STORAGE_KEY } from "../../../config/helpers/variables";

const UserNav = () => {
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY))
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const configurationOptions = [{ name: 'Web Singnage', path: '/userwebsignages' }, { name: 'Location', path: '/userlocations' }, { name: 'Endpoint Group', path: '/userendpointgroups' }, { name: 'Endpoint', path: '/userendpoints' }, { name: 'Distribution List', path: '/distributionList' }, { name: 'Integration', path: '/user/integration' }, { name: 'Web Pages', path: '/webpages' }]

  const userMonitorOptions = [{ name: "Notification history", path: "/notificationhistory" }]

  return (
    <AppBar position='static' style={{ backgroundColor: THEME_COLOR }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "flex", md: "flex", lg: "flex" },
              marginLeft: "-1.5rem",
              marginRight: "1rem",
            }}
          >
            <IconButton
              size='small'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              color='inherit'
              className='userSidebar userSidebarSmall'
            >
              <Sidebar />
            </IconButton>
            <Box className='userNavbar userNavbarSmall'>
              <Link className='userNavbar__link' to="/dashboard" >Dashboard</Link>
              <Link className='userNavbar__link' to="/users" >Users</Link>
              {user?.data?.role == "Administrator" &&
                <>
                  <span className='userNavbar__link'><NavMenu options={configurationOptions} title={"Configuration"} /></span>
                </>
              }
              <span className='userNavbar__link'><NavMenu options={userMonitorOptions} title={"Monitor"} /></span>
            </Box>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            ></Menu>
          </Box>
          <Typography
            variant='h5'
            noWrap
            component='a'
            href=''
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0, marginRight: "-1rem" }}>
            <LogoutMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default UserNav;
