import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import useIntegration from '../../../../hooks/useIntegration';
import AlertMessage from '../../../../components/AlertMessage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function IntegrationDeleteModal({ id, integrationData, setIntegrationData }) {
  const { deleteIntegration } = useIntegration();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [errorMessage, setErrorMessage] = useState(null)

  const destroyIntegration = async () => {
    const response = await deleteIntegration(id);
    if (response.status < 300) {
      const filteredIntegration = integrationData.filter(item => item.id !== id);
      setIntegrationData(filteredIntegration);
    } else {
      setErrorMessage('Something went wrong!')
    }
    handleClose();
  }
  return (
    <div>
      <AlertMessage errorMessage={errorMessage} />
      <IconButton onClick={handleOpen}><DeleteIcon sx={{ color: "red", fontSize: "1.2rem" }} /></IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='templateDeleteModal'>
          <p className='templateDeleteModal__confirm'>Are you sure?</p>
          <div className='templateDeleteModal__buttons'>
            <Button variant='contained' size='small' className='templateDeleteModal__buttons__deleteButton' onClick={destroyIntegration}>Delete</Button>
            <Button variant='contained' size='small' className='templateDeleteModal__buttons__cancelButton' onClick={handleClose}>Cancel</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
