import React, { useEffect } from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard")
    }, 5000)
  }, []);

  return (
    <UserPage>
      <main className='home'>
        <header className='home__header'>
          <div className='home__header__icon'>
            <IconButton className='home__header__icon__crossIcon' onClick={() => navigate("/dashboard")}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className='home__header__heading'>
            <h1 className='home__header__heading__head'>Welcome to NTS</h1>
          </div>
        </header>
      </main>
    </UserPage>
  );
}

export default Home;
