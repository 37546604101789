import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import EndpointForm from '../Components/EndpointForm';
import useEndpoints from '../../../../hooks/useEndpoints';
import AlertMessage from '../../../../components/AlertMessage';

const EditEndpoint = () => {
  const { getEndpointById, updateEndpoint } = useEndpoints();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateEndpoints, setUpdateEndpoints] = useState({
    name: "",
    description: "",
    location_id: "",
    endpoint_group_id: "",
    device_type: "",
    device_identifier: "",
    build_no: "",
    os: "",
    destination: {
      destination_type: "",
      resource_url: "",
      network_distribution_id: "",
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateEndpoint(updateEndpoints, id);
    if (response.status < 300) {
      navigate("/admin/endpoint");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getEndpoint = async () => {
    const response = await getEndpointById(id);
    if (response.status < 300) {
      setUpdateEndpoints(response.endpoint);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getEndpoint();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='endpoint'>
        <header className='endpoint__headerContainer'>
          <div className='endpoint__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/endpoint")} className='endpoint__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpoint__headerContainer__header__heading'>Edit Endpoint</h3>
          </div>
        </header>
        <section className='endpoint__box'>
          <EndpointForm endpoint={updateEndpoints} setEndpoint={setUpdateEndpoints} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditEndpoint;
