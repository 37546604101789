import axios from 'axios';
import { ADMIN_STORAGE_KEY, API_KEY, USER_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const usePackages = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createAdminPackage = async adminPackages => {
    const params = {
      package: {
        name: adminPackages.name,
        price: adminPackages.price,
        duration: adminPackages.duration,
        users_creating_limit: adminPackages.users_creating_limit,
        locations_creating_limit: adminPackages.locations_creating_limit,
        endpoints_creating_limit: adminPackages.endpoints_creating_limit,
        endpoint_groups_creating_limit: adminPackages.endpoint_groups_creating_limit,
        promotion: adminPackages.promotion,
      }
    }
    const response = await axios.post(
      `${API_KEY}/admins/packages`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteAdminPacakge = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/packages/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  const getPackages = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/packages`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const buyPackage = async (id) => {
    const response = await axios.post(
      `${API_KEY}/admins/packages/${id}/buy_package`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateAdminPackage = async adminPackages => {
    const params = {
      package: {
        id: adminPackages.id,
        name: adminPackages.name,
        price: adminPackages.price,
        duration: adminPackages.duration,
        users_creating_limit: adminPackages.users_creating_limit,
        locations_creating_limit: adminPackages.locations_creating_limit,
        endpoints_creating_limit: adminPackages.endpoints_creating_limit,
        endpoint_groups_creating_limit: adminPackages.endpoint_groups_creating_limit,
        promotion: adminPackages.promotion,
      }
    }
    const response = await axios.put(
      `${API_KEY}/admins/packages/${adminPackages.id}`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAdminPackageById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/packages/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  const redirectRequest = async (stripeParams) => {
    const response = await axios.post(
      `${API_KEY}/admins/packages/redirect_request`,
      {
        stripeParams
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };
  return {
    getPackages, buyPackage, redirectRequest, createAdminPackage, deleteAdminPacakge, getAdminPackageById, updateAdminPackage
  };
};
export default usePackages;