import React from 'react';
import TextField from '@mui/material/TextField';
import Selection from '../../../../components/Form/Selection';
import { Button } from '@mui/material';

const CompanyForm = ({ company, setCompany, handleSubmit }) => {

  const statusOptions = [{ value: 1, label: "Active" }, { value: 0, label: "Inactive" }]
  const oktaSSOOptions = [{ value: true, label: "Yes" }, { value: false, label: "No" }]

  const handleChange = e => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: name !== "logo" ? value : e.target.files[0] });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Company Name'
          onChange={handleChange}
          value={company.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='sub_domain'
          label='Sub Domain'
          onChange={handleChange}
          value={company.sub_domain}
          required={true}
          style={{ width: '100%' }}
        />
        <Selection handleChange={handleChange} options={oktaSSOOptions} name={"okta_sso_login"} attributes={company} label={"Okta SSO"} />
        <Selection handleChange={handleChange} options={statusOptions} name={"status"} attributes={company} label={"Status"} />
        <TextField
          type='file'
          name='logo'
          label='Company Logo'
          onChange={handleChange}
          focused
          style={{ width: '100%', marginTop: '2rem' }}
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default CompanyForm;
