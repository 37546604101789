import React from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Selection from '../../../../components/Form/Selection';

const IntegrationForm = ({ integration, setIntegration, handleSubmit }) => {

  const integrationOptions = [{ value: 0, label: "IC Mobile" }, { value: 1, label: "Cisco Vision" }]

  const handleChange = e => {
    const { name, value } = e.target;
    setIntegration({
      ...integration, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <Selection name={"name"} attributes={integration} handleChange={handleChange} label={"Integration Type"} options={integrationOptions} />
        {
          integration.name == 0 &&
            <>
              <TextField
                type='text'
                name='ic_mobile_permanent_token'
                label='Token'
                onChange={handleChange}
                value={integration.ic_mobile_permanent_token}
                required={true}
                style={{ width: '100%', marginBottom: '2rem', marginTop: "2rem" }}
              />
              <TextField
                type='text'
                name='client_id'
                label='Client Id'
                onChange={handleChange}
                value={integration.client_id}
                required={true}
                style={{ width: '100%', marginBottom: '2rem' }}
              />
              <TextField
                type='text'
                name='client_secret'
                label='Client Secret'
                onChange={handleChange}
                value={integration.client_secret}
                required={true}
                style={{ width: '100%' }}
              />
            </>
        }
      { 
      integration.name == 1 && 
        <>
           <TextField
            type='text'
            name='username'
            label='Username'
            onChange={handleChange}
            value={integration.username}
            required={true}
            style={{ width: '100%', marginBottom: '2rem', marginTop: "2rem"  }}
          />
          <TextField
            type='text'
            name='password'
            label='Password'
            onChange={handleChange}
            value={integration.password}
            required={true}
            style={{ width: '100%',  }}
          />
        </>
        } 
        <TextField
          type='text'
          name='base_url'
          label='Base Url'
          onChange={handleChange}
          value={integration.base_url}
          required={true}
          style={{ width: '100%', marginTop: "2rem" }}
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default IntegrationForm;
