import React from 'react';
import { useNavigate } from 'react-router-dom';
import { API_KEY } from '../../../../config/helpers/variables';

const CompanyProfile = ({ user }) => {
  const navigate = useNavigate();
  return (
    <section className='companyProfile'>
      <header className='companyProfile__header'>
        <h1 className='companyProfile__header__heading'>Company Profile</h1>
      </header>
      <div className='companyProfile__companyName'>
        <div className='companyProfile__companyName__name'>
          <img src={`${API_KEY}/${user?.data?.company?.logo?.url}`} alt="" />
          <h1>{user?.data?.company?.name}</h1>
        </div>
        <p>Domain: <span>{user?.data?.company?.sub_domain}</span></p>
        <p>Status: <span>{user?.data?.company?.paid_status}</span></p>
        <div className='companyProfile__companyName__usersDetails' onClick={() => navigate("/users")}>
          <p>Administrator: <span>{user?.data?.user_count?.admins}</span></p>
          <p>Users: <span>{user?.data?.user_count?.notification_users}</span></p>
        </div>
      </div>
      <div className='companyProfile__details'>
        <div className='companyProfile__details__locations'>
          {/* {user?.data?.role === "Administrator" ? */}
          <a href='users' className='companyProfile__details__locations__created' style={{ textDecoration: "none", color: 'inherit' }}>Created Users: {user?.data?.user_count?.admins + user?.data?.user_count?.notification_users}</a>
          {/* :
            <span href='users' className='companyProfile__details__locations__created'>Created Users: <p>{user?.data?.user_count?.admins + user?.data?.user_count?.notification_users}</p></span>
          } */}
          <span className='companyProfile__details__locations__available'>Available: <p>{user?.data?.subscription?.users_limit - user?.data?.user_count?.admins - user?.data?.user_count?.notification_users}</p></span>
          <span className='companyProfile__details__locations__limit'>Limit: <p>{user?.data?.subscription?.users_limit}</p></span>
        </div>
        <div className='companyProfile__details__locations'>
          {user?.data?.role === "Administrator" ?
            <a href='userlocations' className='companyProfile__details__locations__created' style={{ textDecoration: "none", color: 'inherit' }}>Created Locations: {user?.data?.locations_count}</a>
            :
            <span className='companyProfile__details__locations__created'>Created Locations: <p>{user?.data?.locations_count}</p></span>
          }
          <span className='companyProfile__details__locations__available'>Available: <p>{user?.data?.subscription?.locations_limit - user?.data?.locations_count}</p></span>
          <span className='companyProfile__details__locations__limit'>Limit: <p>{user?.data?.subscription?.locations_limit}</p></span>
        </div>
        <div className='companyProfile__details__locations'>
          {user?.data?.role === "Administrator" ?
            <a href='userendpoints' className='companyProfile__details__locations__created' style={{ textDecoration: "none", color: 'inherit' }}>Created Endpoints: {user?.data?.endpoints_count}</a>
            :
            <span className='companyProfile__details__locations__created'>Created Endpoints: <p>{user?.data?.endpoints_count}</p></span>
          }
          <span className='companyProfile__details__locations__available'>Available: <p>{user?.data?.subscription?.endpoints_limit - user?.data?.endpoints_count}</p></span>
          <span className='companyProfile__details__locations__limit'>Limit: <p>{user?.data?.subscription?.endpoints_limit}</p></span>
        </div>
        <div className='companyProfile__details__locations'>
          {user?.data?.role === "Administrator" ?
            <a href='userendpointgroups' className='companyProfile__details__locations__created' style={{ textDecoration: "none", color: 'inherit' }}>Created Endpoint Groups: {user?.data?.endpoint_groups_count}</a>
            :
            <span className='companyProfile__details__locations__created'>Created Endpoint Groups: <p>{user?.data?.endpoint_groups_count}</p></span>
          }
          <span className='companyProfile__details__locations__available'>Available: <p>{user?.data?.subscription?.endpoint_groups_limit - user?.data?.endpoint_groups_count}</p></span>
          <span className='companyProfile__details__locations__limit'>Limit: <p>{user?.data?.subscription?.endpoint_groups_limit}</p></span>
        </div>
      </div>
    </section>
  );
}

export default CompanyProfile;
