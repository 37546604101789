import React from "react";
import AdminPage from "../../../components/PageWrapper/AdminPage";
import AdminTemplateCards from "./Components/AdminTemplateCards";
const AdminDashboard = () => {
  return (
    <AdminPage>
      <main className="dashboard">
        <header className="dashboard__header">
          <h3 className="dashboard__header__heading">Dashboard</h3>
        </header>
        <section className="dashboard__tab">
          <AdminTemplateCards />
        </section>
      </main>
    </AdminPage>
  );
};

export default AdminDashboard;
