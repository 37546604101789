import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import useProfile from '../../../../hooks/useProfile';
import ProfileForm from '../Components/ProfileForm';
import { USER_STORAGE_KEY } from '../../../../config/helpers/variables';
import AlertMessage from '../../../../components/AlertMessage';
import UserPage from '../../../../components/PageWrapper/UserPage';

const EditProfile = () => {
  const { updateProfile } = useProfile();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [updatedProfile, setUpdatedProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateProfile(updatedProfile);
    if (response.status < 300) {
      navigate("/signin")
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getProfile = async () => {
    setUpdatedProfile(user.data);
  }

  useEffect(() => {
    getProfile();
  }, []);


  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='profile'>
        <header className='profile__headerContainer'>
          <div className='profile__headerContainer__header'>
            <IconButton onClick={() => navigate("/profile")} className='profile__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='profile__headerContainer__header__heading'>Edit Profile</h3>
          </div>
        </header>
        <section className='profile__box'>
          <ProfileForm user={updatedProfile} setUser={setUpdatedProfile} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default EditProfile;
