import { Button } from '@mui/material';
import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UserWebSignageTable from './Components/UserWebSignageTable';

const UserWebSignages = () => {
  const navigate = useNavigate();
  return (
    <UserPage>
      <main className='webSignage'>
        <header className='webSignage__headerContainer'>
          <div className='webSignage__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='webSignage__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='webSignage__headerContainer__header__heading'>Web Signage</h3>
          </div>
          <div className='webSignage__headerContainer__submitButton'>
            <Button onClick={() => navigate("/adduserwebsignage")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Web Signage</Button>
          </div>
        </header>
        <section className='webSignage__box'>
          <UserWebSignageTable />
        </section>
      </main>
    </UserPage>
  );
}

export default UserWebSignages;
