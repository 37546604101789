import React from "react";

const CInput = ({
  label,
  type,
  placeholder,
  classes,
  name,
  onChange,
  value,
  style,
  required,
}) => {
  return (
    <div className={`cInput ${classes}`}>
      {label && <label htmlFor=''>{label}</label>}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{ ...style }}
        className={` ${classes}`}
        required={required}
        autoComplete='off'
      />
    </div>
  );
};

export default CInput;
