import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AdminTemplateForm from '../Components/AdminTemplateForm';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import useTemplate from '../../../../hooks/useTemplate';
import AlertMessage from '../../../../components/AlertMessage';

const AdminEditTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getTemplateByIdAdmin, updateTemplateAdmin } = useTemplate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateTemplates, setUpdateTemplates] = useState({
    id: id,
    name: "",
    subject: "",
    body: "",
    audio: "",
    font_color: "#808080",
    background_color: "#FFFFFF",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      ...updateTemplates,
    };
    const response = await updateTemplateAdmin(data);
    if (response.status < 300) {
      navigate("/admin/dashboard");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getTemplates = async () => {
    const response = await getTemplateByIdAdmin(id);
    if (response.status < 300) {
      setUpdateTemplates({ ...updateTemplates, name: response.template.name, subject: response.template.subject, body: response.template.body, audio: response.template.audio, font_color: response.template.font_color, background_color: response.template.background_color });
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getTemplates();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='template'>
        <header className='template__headerContainer'>
          <div className='template__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='template__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='template__headerContainer__header__heading'>Edit Template</h3>
          </div>
        </header>
        <section className='template__box'>
          <AdminTemplateForm template={updateTemplates} setTemplate={setUpdateTemplates} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AdminEditTemplate;
