import React, { useEffect, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Link, useNavigate } from 'react-router-dom';
import TemplateCardsMenu from './AdminTemplateCardsMenu';
import useTemplate from '../../../../hooks/useTemplate';
import AlertMessage from '../../../../components/AlertMessage';

const AdminTemplateCards = () => {
  const navigate = useNavigate();
  const { getAllTemplateAdmin } = useTemplate();
  const [templatesList, setTemplatesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null)


  const getTemplates = async () => {
    const response = await getAllTemplateAdmin();
    if (response.status < 300) {
      setTemplatesList(response.templates);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getTemplates();
  }, []);


  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <main className='dashboard__box__notifications'>
        {templatesList.map((template, index) => (
          <div className='dashboard__box__notifications__notificationBox' style={{ backgroundColor: `${template.background_color}` }} key={template?.id}>
            <span className='dashboard__box__notifications__notificationBox__menuIcon'><TemplateCardsMenu menuColor={`${template.font_color} `} id={template.id} templatesList={templatesList} setTemplatesList={setTemplatesList} /></span>
            <div onClick={() => navigate(`/admin/viewtemplate/${template?.id}`)}>
              <p className='dashboard__box__notifications__notificationBox__name' style={{ color: `${template?.font_color}` }}>{template?.name?.length > 10 ? template?.name.slice(0, 10) + "..." : template?.name}</p>
              <p className='dashboard__box__notifications__notificationBox__subject' style={{ color: `${template?.font_color}` }}>{template.subject.length > 12 ? template?.subject.slice(0, 12) + "..." : template?.subject}</p>
            </div>
          </div>
        ))}
        <Link to="/admin/template" className='dashboard__box__notifications__notificationBox'>
          <p className='dashboard__box__notifications__notificationBox__addIcon'><AddCircleOutlineOutlinedIcon /></p>
          <p className='dashboard__box__notifications__notificationBox__subject'>Add Template</p>
        </Link>
      </main>
    </>
  );
}

export default AdminTemplateCards;
