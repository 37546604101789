import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import EndpointGroupForm from '../Components/EndpointGroupForm';
import useEndpointGroups from '../../../../hooks/useEndpointGroups';
import AlertMessage from '../../../../components/AlertMessage';

const EditEndpointGroup = () => {
  const { getEndpointGroupById, updateEndpointGroup } = useEndpointGroups();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateEndpointGroups, setUpdateEndpointGroups] = useState({
    name: "",
    description: "",
    endpoint_type: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateEndpointGroup(updateEndpointGroups, id);
    if (response.status < 300) {
      navigate("/admin/endpointgroup");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getEndpointGroup = async () => {
    const response = await getEndpointGroupById(id);
    if (response.status < 300) {
      setUpdateEndpointGroups({ ...updateEndpointGroups, name: response.endpoint_group.name, description: response.endpoint_group.description, endpoint_type: response.endpoint_group.endpoint_type });
    } else {
      setErrorMessage('Something went wrong!')
    }
  }
  useEffect(() => {
    getEndpointGroup();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='endpointGroup'>
        <header className='endpointGroup__headerContainer'>
          <div className='endpointGroup__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/endpointgroup")} className='endpointGroup__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='endpointGroup__headerContainer__header__heading'>Edit Endpoint Group</h3>
          </div>
        </header>
        <section className='endpointGroup__box'>
          <EndpointGroupForm endpointGroup={updateEndpointGroups} setEndpointGroup={setUpdateEndpointGroups} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditEndpointGroup;
