import axios from 'axios';
import { API_KEY, USER_STORAGE_KEY, ADMIN_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useLocations = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createLocation = async location => {
    const params = {
      location: {
        name: location.name,
        description: location.description,
        web_signage_id: location.web_signage_id,
      }
    }
    const response = await axios.post(
      `${API_KEY}/admins/locations`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllLocations = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/locations`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteLocation = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/locations/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateLocation = async location => {
    const params = {
      location: {
        id: location.id,
        name: location.name,
        description: location.description,
        web_signage_id: location.web_signage_id,
      }
    }
    const response = await axios.put(
      `${API_KEY}/admins/locations/${params.location.id}`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getLocationById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/locations/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const createUserLocation = async location => {
    const params = {
      location: {
        name: location.name,
        description: location.description,
        web_signage_id: location.web_signage_id,
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/locations`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllUserLocations = async () => {
    const response = await axios.get(
      `${API_KEY}/users/locations`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteUserLocation = async id => {
    const response = await axios.delete(
      `${API_KEY}/users/locations/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateUserLocation = async location => {
    const params = {
      location: {
        name: location.name,
        description: location.description,
        web_signage_id: location.web_signage_id,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users/locations/${location.id}`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getUserLocationById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/users/locations/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  return {
    createLocation, getAllLocations, deleteLocation, updateLocation, getLocationById, createUserLocation, getAllUserLocations, deleteUserLocation, updateUserLocation, getUserLocationById,
  };
};
export default useLocations;
