import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AdminNotificationHistoryTable from './Components/AdminNotificationHistoryTable';

const AdminNotificationHistory = () => {
  const navigate = useNavigate();
  return (
    <AdminPage>
      <main className='notificationHistory'>
        <header className='notificationHistory__headerContainer'>
          <div className='notificationHistory__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='notificationHistory__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='notificationHistory__headerContainer__header__heading'>Notification History</h3>
          </div>
        </header>
        <section className='notificationHistory__box'>
          <AdminNotificationHistoryTable/>
        </section>
      </main>
    </AdminPage>
  );
}

export default AdminNotificationHistory;
