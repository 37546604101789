import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SelectionMultiple from '../../../../components/Form/SelectionMultiple';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import useEndpointGroups from '../../../../hooks/useEndpointGroups';
import AlertMessage from '../../../../components/AlertMessage';

const AdminDevicesTab = ({ onChange, next, prev, endpointGroupsList, setEndpointGroupsList, changeHandler, notifications }) => {
  const { getAllEndpointGroups } = useEndpointGroups();
  const [errorMessage, setErrorMessage] = useState(null)
  
  const getEndpointGroups = async () => {
    const response = await getAllEndpointGroups();
    if (response.status < 300) {
      setEndpointGroupsList(response.endpoint_groups);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  const endpointGroupsOptions = endpointGroupsList.map((row) => ({ value: row.id, label: row.name }))

  useEffect(() => {
    getEndpointGroups();
  }, []);

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <main className='locationTab'>
        <h1>What devices do you want to send this to?</h1>
        <SelectionMultiple options={endpointGroupsOptions} handleChange={changeHandler} name={"endpoint_groups_id"} label={"Select Devices"} attributes={notifications} />
        <div className='locationTab__buttons'>
          <Button className='locationTab__buttons__prev' variant='contained' onClick={(e) => onChange(e, prev)}><SkipPreviousIcon sx={{ fontSize: '1rem', marginTop: '-.1rem' }} />Prev</Button>
          <Button className='locationTab__buttons__next' variant='contained' onClick={(e) => onChange(e, next)}>Next<SkipNextIcon sx={{ fontSize: '1rem', marginTop: '-.1rem' }} /></Button>
        </div>
      </main>
    </>
  );
}

export default AdminDevicesTab;
