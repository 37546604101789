import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserEndpointDeleteModal from './UserEndpointDeleteModal';
import useEndpoints from '../../../../hooks/useEndpoints';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';

export default function UserEndpointTable() {
  const navigate = useNavigate();
  const { getAllUserEndpoints } = useEndpoints();
  const [endpointsList, setEndpointsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [endpointData, setEndpointData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? endpointsList.filter((item) => item?.name.toLowerCase().includes(value.toLowerCase()) || item?.description.toLowerCase().includes(value.toLowerCase()) || item?.location?.name.toLowerCase().includes(value.toLowerCase()) || item?.endpoint_group?.name.toLowerCase().includes(value.toLowerCase()))
        : endpointsList
    setEndpointData(searchFilter)
  }


  const getEndpoints = async () => {
    const response = await getAllUserEndpoints();
    if (response.status < 300) {
      setEndpointsList(response.endpoints);
      setEndpointData(response.endpoints);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getEndpoints();
  }, []);

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead'>Name</TableCell>
              <TableCell className='table__tableHead' align="left">Description</TableCell>
              <TableCell className='table__tableHead' align="left">Location</TableCell>
              <TableCell className='table__tableHead' align="left">Endpoint Group</TableCell>
              <TableCell className='table__tableHead' align="left">Destination type</TableCell>
              <TableCell className='table__tableHead' align="left">Resource url</TableCell>
              <TableCell className='table__tableHead' align="left">Network distribution Id</TableCell>
              <TableCell className='table__tableHead' align="left">User</TableCell>
              <TableCell className='table__tableHead' align="left">Device Type</TableCell>
              <TableCell className='table__tableHead' align="left">Device Identifier</TableCell>
              <TableCell className='table__tableHead' align="left">Build#</TableCell>
              <TableCell className='table__tableHead' align="left">OS</TableCell>
              <TableCell className='table__tableHead' align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {endpointData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row?.name}
                </TableCell>
                <TableCell className='table__tableRow' align="left">{row?.description}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.location?.name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.endpoint_group?.name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.destination?.destination_type}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.destination?.resource_url}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.destination?.network_distribution_id}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.creator_id}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.device_type}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.device_identifier}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.build_no}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.os_version}</TableCell>
                <TableCell className='table__tableRow' style={{ display: "flex", justifyContent: "left" }}>
                  {!(row?.creator_type === "default") ?
                    <>
                      <IconButton onClick={() => navigate(`/edituserendpoint/${row.id}`)}>
                        <BorderColorIcon sx={{ color: "blue", fontSize: "1.2rem" }} />
                      </IconButton>
                      <UserEndpointDeleteModal id={row.id} endpointData={endpointData} setEndpointData={setEndpointData} />
                    </>
                    :
                    <p style={{ lineHeight: "22px", color: "lightgray" }}><small>System Endpoint!</small></p>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={endpointData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
