import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import PackagesCards from './Components/PackagesCards';
import UserPage from '../../../components/PageWrapper/UserPage';

const Packages = () => {
  const navigate = useNavigate();
  return (
    <UserPage>
      <main className='packages'>
        <header className='packages__headerContainer'>
          <div className='packages__headerContainer__header'>
            <IconButton onClick={() => navigate("/profile")} className='packages__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='packages__headerContainer__header__heading'>Packages Plan</h3>
          </div>
        </header>
        <section className='packages__box'>
          <PackagesCards />
        </section>
      </main>
    </UserPage>
  );
}

export default Packages;
