import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import useDistributionList from '../../../../hooks/useDistributionList';
import AlertMessage from '../../../../components/AlertMessage';
import Select from 'react-select'
import SuccessMessage from '../../../../components/SuccessMessage';
import useUsers from '../../../../hooks/useUsers';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ViewDistributionListUnSubscriptionModal({ id }) {

  const [open, setOpen] = React.useState(false);
  const [distributionListData, setDistributionListData] = useState([]);
  const [distributionList, setDistributionList] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { getDistributionListById } = useDistributionList();
  const { unSubscribeDistributionList } = useUsers();

  const distributionListOptions = distributionListData.map((row) => ({ label: row?.email, value: row?.id }));

  const getDistributionList = async () => {
    debugger
    const response = await getDistributionListById(id);
    if (response.status < 300) {
      response?.distribution_list?.subscribers &&
        setDistributionListData(response?.distribution_list?.subscribers);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  const handleSubmit = async e => {
    debugger
    e.preventDefault();
    const userIds = distributionList.map((item) => (item.value))
    const response = await unSubscribeDistributionList(userIds, id);
    if (response.status < 300) {
      handleClose();
      setSuccessMessage(response.message)
    } else if (response.status > 300) {
      setErrorMessage(response.message);
      handleClose();
    }
  }



  useEffect(() => {
    getDistributionList();
  }, []);



  return (
    <div>
      <SuccessMessage successMessage={successMessage} />
      <AlertMessage errorMessage={errorMessage} />
      <Button variant='contained' size='small' style={{ textTransform: "capitalize", color: "red", backgroundColor: "#FAFAFA", fontWeight: "500", boxShadow: "none" }} onClick={handleOpen}>Unsubscribe</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='templateDeleteModal'>
          <h4 style={{ marginBottom: "1rem" }}>Select Users to Unsubscribe</h4>
          <div style={{ marginBottom: "2rem" }}>
            <Select
              options={distributionListOptions}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              defaultValue={distributionList}
              onChange={setDistributionList}
            />
          </div>
          <div className='templateDeleteModal__buttons'>
            <Button variant='contained' color="success" size='small' style={{ backgroundColor: "#FF0000", textTransform: 'capitalize', marginRight: ".5rem" }} onClick={handleSubmit}>Unsubscribe</Button>
            <Button variant='contained' size='small' className='templateDeleteModal__buttons__cancelButton' onClick={handleClose}>Cancel</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
