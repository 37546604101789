import AdminSignIn from "../admin/Pages/Authentication/AdminSignIn";
import SignIn from "../users/Pages/Authentication/SignIn";
import AdminDashboard from "../admin/Pages/AdminDashboard";
import Dashboard from "../users/Pages/Dashboard";
import Template from "../users/Pages/Template";
import EditTemplate from "../users/Pages/Template/EditTemplate";
import Home from "../users/Pages/Home";
import ViewTemplate from "../users/Pages/Template/ViewTemplate";
import AdminEditTemplate from '../admin/Pages/AdminTemplate/AdminEditTemplate';
import AdminTemplate from '../admin/Pages/AdminTemplate/';
import AdminViewTemplate from '../admin/Pages/AdminTemplate/AdminViewTemplate';
import AdminNotification from "../admin/Pages/AdminNotification";
import WebSignage from "../admin/Pages/WebSignage";
import Location from "../admin/Pages/Location";
import EndpointGroup from "../admin/Pages/EndpointGroup";
import Endpoint from "../admin/Pages/Endpoints";
import Integration from "../admin/Pages/Integration";
import System from "../admin/Pages/System";
import SSO from "../admin/Pages/SSO";
import Logs from "../admin/Pages/Logs";
import Alerts from "../admin/Pages/Alerts";
import AdminNotificationHistory from "../admin/Pages/AdminNotificationHistory";
import NotificationHistory from "../users/Pages/NotificationHistory";
import Notification from "../users/Pages/Notification";
import AddEndpointGroup from "../admin/Pages/EndpointGroup/AddEndpointGroup";
import EditEndpointGroup from "../admin/Pages/EndpointGroup/EditEndpointGroup";
import AddLocation from "../admin/Pages/Location/AddLocation";
import EditLocation from "../admin/Pages/Location/EditLocation";
import AddEndpoint from "../admin/Pages/Endpoints/AddEndpoint";
import EditEndpoint from "../admin/Pages/Endpoints/EditEndpoint";
import EditWebSignage from "../admin/Pages/WebSignage/EditWebSignage";
import AddWebSignage from "../admin/Pages/WebSignage/AddWebSignage";
import AddIntegration from "../admin/Pages/Integration/AddIntegration";
import EditIntegration from "../admin/Pages/Integration/EditIntegration";
import AddCompany from "../admin/Pages/Companies/AddCompany";
import EditCompany from "../admin/Pages/Companies/EditCompany";
import PaymentSuccess from '../users/Pages/PaymentPage/PaymentSuccess';
import EditAdminProfile from "../admin/Pages/AdminProfile/EditAdminProfile";
import EditProfile from '../users/Pages/Profile/EditProfile';
import Packages from "../users/Pages/Packages";
import Profile from "../users/Pages/Profile";
import PaymentPage from "../users/Pages/PaymentPage";
import Companies from "../admin/Pages/Companies";
import EditAdminUser from "../admin/Pages/AdminUsers/EditAdminUser";
import AddAdminUser from '../admin/Pages/AdminUsers/AddAdminUser';
import AdminUsers from '../admin/Pages/AdminUsers/';
import UsersView from "../admin/Pages/Companies/UsersView";
import AdminPrivateRoute from "./components/AdminPrivateRoute";
import UserPrivateRoute from "./components/UserPrivateRoute";
import UserEndpoints from "../users/Pages/UserEndpoints";
import EditUserEndpoint from "../users/Pages/UserEndpoints/EditUserEndpoint";
import AddUserEndpoint from "../users/Pages/UserEndpoints/AddUserEndpoint";
import UserLocations from "../users/Pages/UserLocations";
import AddUserLocation from "../users/Pages/UserLocations/AddUserLocation";
import EditUserLocation from "../users/Pages/UserLocations/EditUserLocation";
import UserWebSignages from "../users/Pages/UserWebSignage";
import EditUserWebSignage from "../users/Pages/UserWebSignage/EditUserWebSignage";
import AddUserWebSignage from "../users/Pages/UserWebSignage/AddUserWebSignage";
import UserEndpointGroups from "../users/Pages/UserEndpointGroups";
import EditUserEndpointGroup from "../users/Pages/UserEndpointGroups/EditUserEndpointGroup";
import AddUserEndpointGroup from "../users/Pages/UserEndpointGroups/AddUserEndpointGroup";
import Users from '../users/Pages/Users';
import AddUser from '../users/Pages/Users/AddUser';
import EditUser from '../users/Pages/Users/EditUser';
import AdminPackages from "../admin/Pages/AdminPackages";
import AddAdminPackage from "../admin/Pages/AdminPackages/AddAdminPackage";
import EditAdminPackage from "../admin/Pages/AdminPackages/EditAdminPackage";
import ICMobile from "../admin/Pages/ICMobile";
import AddICMobile from "../admin/Pages/ICMobile/AddICMobile";
import EditICMobile from "../admin/Pages/ICMobile/EditICMobile";
import DistributionList from "../users/Pages/DistributionList";
import AddDistributionList from "../users/Pages/DistributionList/AddDistributionList";
import EditDistributionList from "../users/Pages/DistributionList/EditDistributionList";
import AddUserIntegration from "../users/Pages/Integration/AddIntegration";
import UserIntegration from "../users/Pages/Integration";
import EditUserIntegration from "../users/Pages/Integration/EditIntegration";
import WebPages from "../users/Pages/WebPages";
import HtmlPage from "../users/Pages/HtmlPage";
import TermsAndService from "../components/TermsAndService/TermsAndService";
import ViewNotificationHistory from "../users/Pages/NotificationHistory/ViewNotificationHistory/index"
export const routes = [
  {
    path: "/admin/signin",
    component: AdminSignIn,
    isPrivate: false,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/template",
    component: AdminTemplate,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/edittemplate/:id",
    component: AdminEditTemplate,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/viewtemplate/:id",
    component: AdminViewTemplate,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/notification/:id",
    component: AdminNotification,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/websignage",
    component: WebSignage,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/addwebsignage",
    component: AddWebSignage,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/editwebsignage/:id",
    component: EditWebSignage,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/location",
    component: Location,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/addlocation",
    component: AddLocation,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/editlocation/:id",
    component: EditLocation,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/endpointgroup",
    component: EndpointGroup,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/addendpointgroup",
    component: AddEndpointGroup,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/editendpointgroup/:id",
    component: EditEndpointGroup,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/companies/:id/users",
    component: UsersView,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/users",
    component: AdminUsers,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/adduser",
    component: AddAdminUser,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/edituser/:id",
    component: EditAdminUser,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/endpoint",
    component: Endpoint,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/addendpoint",
    component: AddEndpoint,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/editendpoint/:id",
    component: EditEndpoint,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/integration",
    component: Integration,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/addintegration",
    component: AddIntegration,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/editintegration/:id",
    component: EditIntegration,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/system",
    component: System,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/companies",
    component: Companies,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/editadminprofile",
    component: EditAdminProfile,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/addcompany",
    component: AddCompany,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/editcompany/:id",
    component: EditCompany,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/sso",
    component: SSO,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/logs",
    component: Logs,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/alerts",
    component: Alerts,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/notificationhistory",
    component: AdminNotificationHistory,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/packages",
    component: AdminPackages,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/packages/add",
    component: AddAdminPackage,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/packages/:id/edit",
    component: EditAdminPackage,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/icmobile",
    component: ICMobile,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/icmobile/add",
    component: AddICMobile,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },
  {
    path: "/admin/icmobile/:id/edit",
    component: EditICMobile,
    isPrivate: true,
    privacyChecker: AdminPrivateRoute
  },


  //User routes
  {
    path: "/",
    component: Home,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "home"
  },

  {
    path: "/signin",
    component: SignIn,
    isPrivate: false,
    privacyChecker: UserPrivateRoute,
    name: "signin"
  },

  {
    path: "/dashboard",
    component: Dashboard,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "dashboard"
  },
  {
    path: "/template",
    component: Template,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "template"
  },
  {
    path: "/edittemplate/:id",
    component: EditTemplate,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "template"
  },
  {
    path: "/viewtemplate/:id",
    component: ViewTemplate,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "template"
  },
  {
    path: "/notification/:id",
    component: Notification,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "template"
  },
  {
    path: "/editprofile",
    component: EditProfile,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "profile"
  },
  {
    path: "/viewnotificationhistory",
    component: ViewNotificationHistory,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "profile"
  },
  {
    path: "/notificationhistory",
    component: NotificationHistory,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "notification"
  },
  {
    path: "/checkoutSubscription/:id",
    component: PaymentPage,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "subscription"

  },
  {
    path: "/paymentsuccess",
    component: PaymentSuccess,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "payment"
  },
  {
    path: "/packages",
    component: Packages,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "/packages"
  },
  {
    path: "/profile",
    component: Profile,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "profile"
  },
  {
    path: "/userendpoints",
    component: UserEndpoints,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator",],
    name: "endpoint"
  },
  {
    path: "/edituserendpoint/:id",
    component: EditUserEndpoint,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "endpoint"
  },
  {
    path: "/adduserendpoint",
    component: AddUserEndpoint,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "endpoint"
  },
  {
    path: "/userlocations",
    component: UserLocations,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "location"
  },
  {
    path: "/edituserlocation/:id",
    component: EditUserLocation,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "location"
  },
  {
    path: "/adduserlocation",
    component: AddUserLocation,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "location"
  },
  {
    path: "/userwebsignages",
    component: UserWebSignages,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "websignage"
  },
  {
    path: "/edituserwebsignage/:id",
    component: EditUserWebSignage,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "websignage"
  },
  {
    path: "/adduserwebsignage",
    component: AddUserWebSignage,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "websignage"
  },
  {
    path: "/userendpointgroups",
    component: UserEndpointGroups,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "endpointGroups"
  },
  {
    path: "/edituserendpointgroup/:id",
    component: EditUserEndpointGroup,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "endpointGroups"
  },
  {
    path: "/adduserendpointgroup",
    component: AddUserEndpointGroup,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "endpointGroups"
  },
  {
    path: "/users",
    component: Users,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator", "Notification User"],
    name: "users"
  },
  {
    path: "/edituser/:id",
    component: EditUser,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "edituser"
  },
  {
    path: "/adduser",
    component: AddUser,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "adduser"
  },
  {
    path: "/distributionList",
    component: DistributionList,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "distributionList"
  },
  {
    path: "/distribution_list/add",
    component: AddDistributionList,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "distribution_list"
  },
  {
    path: "/distribution_list/:id/edit",
    component: EditDistributionList,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "distribution_list"
  },
  {
    path: "/user/integration",
    component: UserIntegration,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "integration"

  },
  {
    path: "/user/addintegration",
    component: AddUserIntegration,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "integration"
  },
  {
    path: "/user/editintegration/:id",
    component: EditUserIntegration,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "integration"
  },
  {
    path: "/user/editintegration/:id",
    component: EditUserIntegration,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "integration"
  },
  {
    path: "/webpages",
    component: WebPages,
    isPrivate: true,
    privacyChecker: UserPrivateRoute,
    userRole: ["Administrator"],
    name: "webpages"
  },
  //public route
  {
    path: "/pages/:id/:access_token",
    component: HtmlPage,
    isPrivate: false,
  },
  {
    path: "/terms_and_services",
    component: TermsAndService,
    isPrivate: false,
  },
]