import axios from 'axios';
import { API_KEY, USER_STORAGE_KEY, ADMIN_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useEndpointGroups = () => {
  const { handleErrors } = useHandleErrors();
  const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY));
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getHeaders = () => {
    if (user) {
      return {
        headers: {
          Authorization: user.token,
        },
      };
    }
  };

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createEndpointGroup = async endpoint_group => {
    const params = {
      endpoint_group: {
        name: endpoint_group.name,
        description: endpoint_group.description,
        endpoint_type: endpoint_group.endpoint_type,
      }
    }
    const response = await axios.post(
      `${API_KEY}/admins/endpoint_groups`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllEndpointGroups = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/endpoint_groups`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteEndpointGroup = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/endpoint_groups/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateEndpointGroup = async (endpoint_group, id) => {
    const params = {
      endpoint_group: {
        name: endpoint_group.name,
        description: endpoint_group.description,
        endpoint_type: endpoint_group.endpoint_type,
      }
    }
    const response = await axios.put(
      `${API_KEY}/admins/endpoint_groups/${id}`,
      {
        ...params
      },
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getEndpointGroupById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/endpoint_groups/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };



  const createUserEndpointGroup = async endpoint_group => {
    const params = {
      endpoint_group: {
        name: endpoint_group.name,
        description: endpoint_group.description,
        endpoint_type: endpoint_group.endpoint_type,
      }
    }
    const response = await axios.post(
      `${API_KEY}/users/endpoint_groups`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllUserEndpointGroups = async () => {
    const response = await axios.get(
      `${API_KEY}/users/endpoint_groups`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteUserEndpointGroup = async id => {
    const response = await axios.delete(
      `${API_KEY}/users/endpoint_groups/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateUserEndpointGroup = async endpoint_group => {
    const params = {
      endpoint_group: {
        id: endpoint_group.id,
        name: endpoint_group.name,
        description: endpoint_group.description,
        endpoint_type: endpoint_group.endpoint_type,
      }
    }
    const response = await axios.put(
      `${API_KEY}/users/endpoint_groups/${params.endpoint_group.id}`,
      {
        ...params
      },
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getUserEndpointGroupById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/users/endpoint_groups/${id}`,
      getHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  return {
    createEndpointGroup, getAllEndpointGroups, deleteEndpointGroup, updateEndpointGroup, getEndpointGroupById, createUserEndpointGroup, getAllUserEndpointGroups, deleteUserEndpointGroup, updateUserEndpointGroup, getUserEndpointGroupById,
  };
};
export default useEndpointGroups;
