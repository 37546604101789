import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import LocationsTable from './Components/LocationsTable';
import AddIcon from '@mui/icons-material/Add';
const Location = () => {
  const navigate = useNavigate();

  return (
    <AdminPage>
      <main className='location'>
        <header className='location__headerContainer'>
          <div className='location__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='location__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='location__headerContainer__header__heading'>Location</h3>
          </div>
          <div className='location__headerContainer__submitButton'>
            <Button onClick={() => navigate("/admin/addlocation")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Location</Button>
          </div>
        </header>
        <section className='location__box'>
          <LocationsTable />
        </section>
      </main>
    </AdminPage>
  );
}

export default Location;
