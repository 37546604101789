import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UsersTable from './Components/UsersTable';
import { USER_STORAGE_KEY } from '../../../config/helpers/variables';

const Users = () => {
  const currentUser = JSON.parse(sessionStorage.getItem(USER_STORAGE_KEY))
  const navigate = useNavigate();
  return (
    <UserPage>
      <main className='users'>
        <header className='users__headerContainer'>
          <div className='users__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='users__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='users__headerContainer__header__heading'>Users</h3>
          </div>
          <div className='users__headerContainer__submitButton'>
            {currentUser?.data?.role === "Administrator" &&
              <Button onClick={() => navigate("/adduser")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />User</Button>
            }
          </div>
        </header>
        <section className='users__box'>
          <UsersTable />
        </section>
      </main>
    </UserPage>
  );
}

export default Users;