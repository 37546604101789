import React from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

const ICMobileForm = ({ icMobile, setICMobile, handleSubmit }) => {

  const handleChange = e => {
    const { name, value } = e.target;
    setICMobile({
      ...icMobile, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={icMobile.name}
          required={true}
          style={{ width: '100%' }}
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default ICMobileForm;
