import React from "react";
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
//   Link,
//   PDFViewer
// } from "@react-pdf/renderer";

// const styles = StyleSheet.create({
//   viewer: {
//     width: window.innerWidth,
//     height: window.innerHeight,
//   },
//   IDMNTS_Termsof_ServiceTitle: {
//     fontWeight: "bolder",
//     fontSize: "24px",
//   },
//   InterpretationTitle: {
//     marginTop: "20px",
//   },
//   Terms_Condition_purpose: {
//     marginTop: "3px",
//     fontSize: "12px",
//   },
//   TermsConditionsPurposeBolderText: {
//     fontWeight: "1000",
//     fontSize: "14px",
//     position: "relative",
//     left: "20px",
//   },
//   TermsandServiceText: {
//     marginTop: "20px",
//     fontSize: "12px",
//   },
//   listItem: {
//     display: "flex",
//     marginTop: "10px",
//     flexDirection: "row",
//     marginBottom: 5,
//     alignItems: "center",
//   },
//   listItemNumber: {
//     width: 5,
//     height: 5,
//     borderRadius: 5,
//     backgroundColor: "black",
//     marginLeft: "10px",
//   },
//   listItemText: {
//     fontSize: "12px",
//     flex: 1,
//     marginLeft: "15px",
//   },
// });

export default function TermsAndService() {
  return (
    // <PDFViewer style={styles.viewer}>
    //   <Document>
    //     <Page>
    //       <View
    //         style={{
    //           margin: "5px",
    //           padding: "5px",
    //         }}
    //       >
    //         <View>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             IDMNTS Terms of Service
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Effective Date: July 2, 2022
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Please read these terms and conditions carefully before using
    //             Our Service.
    //           </Text>
    //         </View>
    //         <View style={styles.InterpretationTitle}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Interpretation and Definitions
    //           </Text>
    //         </View>
    //         <View style={styles.InterpretationTitle}>
    //           <Text style={styles.Interpretation}>Interpretation</Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             The words of which the initial letter is capitalized have
    //             meanings defined under the following conditions. The following
    //             definitions shall have the same meaning regardless of whether
    //             they appear in singular or in plural.
    //           </Text>
    //         </View>
    //         <View style={styles.InterpretationTitle}>
    //           <Text style={styles.Interpretation}>Definitions</Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             For the purposes of these Terms and Conditions:
    //           </Text>
    //         </View>
    //         <View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Application
    //               </Text>{" "}
    //               means the software program provided by the Company downloaded
    //               by You on any electronic device, named IDM NTS
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Affiliate{" "}
    //               </Text>{" "}
    //               means an entity that controls, is controlled by or is under
    //               common control with a party, where "control" means ownership
    //               of 50% or more of the shares, equity interest or other
    //               securities entitled to vote for election of directors or other
    //               managing authority.
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Authorized{" "}
    //               </Text>{" "}
    //               Users means individuals who are authorized by Customer to use
    //               the Services, including those who have been supplied user
    //               identifications and passwords by Customer. Authorized Users
    //               may include the employees, consultants, contractors, agents,
    //               or other designees of Customer and its Affiliates
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Country{" "}
    //               </Text>{" "}
    //               refers to: California, United States
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Company{" "}
    //               </Text>{" "}
    //               (referred to as either "the Company", "We", "Us" or "Our" in
    //               this Agreement) refers to IDM Groups LLC, 221 N Third St #300
    //               Burbank CA 91502.
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Device{" "}
    //               </Text>{" "}
    //               means any device that can access the Service such as a
    //               computer, a cellphone or a digital tablet.
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Service{" "}
    //               </Text>{" "}
    //               refers to the Application.
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 Terms and Conditions{" "}
    //               </Text>{" "}
    //               (also referred as "Terms") mean these Terms and Conditions
    //               that form the entire agreement between You and the Company
    //               regarding the use of the Service.
    //             </Text>
    //           </View>
    //           <View style={styles.listItem}>
    //             <Text style={styles.listItemNumber}></Text>
    //             <Text style={styles.listItemText}>
    //               <Text style={styles.TermsConditionsPurposeBolderText}>
    //                 You{" "}
    //               </Text>{" "}
    //               means the individual accessing or using the Service, or the
    //               company, or other legal entity on behalf of which such
    //               individual is accessing or using the Service, as applicable.
    //             </Text>
    //           </View>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Acknowledgment
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             These are the Terms and Conditions governing the use of this
    //             Service and the agreement that operates between You and the
    //             Company. These Terms and Conditions set out the rights and
    //             obligations of all users regarding the use of the Service.
    //           </Text>
    //         </View>
    //         <View
    //           style={{
    //             marginTop: "30px",
    //           }}
    //         >
    //           <Text style={styles.TermsandServiceText}>
    //             Your access to and use of the Service is conditioned on Your
    //             acceptance of and compliance with these Terms and Conditions.
    //             These Terms and Conditions apply to all visitors, users and
    //             others who access or use the Service.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             By accessing or using the Service You agree to be bound by these
    //             Terms and Conditions. If You disagree with any part of these
    //             Terms and Conditions then You may not access the Service.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             You represent that you are over the age of 18. The Company does
    //             not permit those under 18 to use the Service.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Your access to and use of the Service is also conditioned on
    //             Your acceptance of and compliance with the Privacy Policy of the
    //             Company. Our Privacy Policy describes Our policies and
    //             procedures on the collection, use and disclosure of Your
    //             personal information when You use the Application or the Website
    //             and tells You about Your privacy rights and how the law protects
    //             You. Please read Our Privacy Policy carefully before using Our
    //             Service.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             User Accounts
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             In order to access certain services or features of our Site, you
    //             may be required to create an account, profile. If any portion of
    //             the Site requires you to open an account or create a user
    //             profile, the information you provide must be truthful and
    //             accurate. User accounts, profiles, usernames, and passwords are
    //             associated with one individual only and you agree that you will
    //             never allow access by another person or entity at any time and
    //             to never access the account, profile, username, or password of
    //             another person or entity at any time. You must notify IDM
    //             GroupsS immediately of any unauthorized use of your account or
    //             of any other breach in security that you are aware of.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Our Site Content
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Our Site contains content owned, operated, licensed, and/or
    //             controlled by IDM Groups that is protected by copyright,
    //             trademark, trade secret, or other proprietary rights ("Site
    //             Content"). IDM Groups retain all rights in such Site Content.
    //             IDM Groups grants you a limited, revocable, nonsublicensable
    //             right to view the Site Content solely for your internal use of
    //             the Site. Additionally, certain Site Content may include, but is
    //             not limited to, icons, photographs, white papers, product
    //             documentation, technology overviews, and implementation guides.
    //             The trademarks, logos, and service marks ("Marks") displayed on
    //             our Site are the property of IDM Groups or other third parties.
    //             IDM Groups is a registered trademark of IDM Groups and/or its
    //             affiliates. IDM Groups Marks may be used under limited
    //             circumstances in strict accordance with our Trademark Guidelines
    //             (pdf). Otherwise, you are not permitted to use these Marks
    //             without the prior written consent of IDM Groups or the third
    //             party that owns the Mark.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Your Content
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             We do not claim any ownership rights in any text, files, images,
    //             photos, video, sounds, software, works of authorship, or other
    //             materials that you upload or otherwise provide to our Site
    //             (collectively, "User Content").You cannot post, modify,
    //             distribute, or reproduce in any way copyrighted or other
    //             proprietary materials without obtaining the prior written
    //             consent of the copyright owner of such materials. We may
    //             terminate an account and/or deny access to any user who is
    //             alleged to have infringed the copyright or proprietary rights of
    //             another.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             All User Content is considered nonconfidential and public.
    //             Please do not submit any confidential or private information.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Links to Other Websites
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "30px" }}>
    //           <Text style={styles.TermsandServiceText}>
    //             Our Service may contain links to third-party web sites or
    //             services that are not owned or controlled by the Company.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             The Company has no control over, and assumes no responsibility
    //             for, the content, privacy policies, or practices of any third
    //             party web sites or services. You further acknowledge and agree
    //             that the Company shall not be responsible or liable, directly or
    //             indirectly, for any damage or loss caused or alleged to be
    //             caused by or in connection with the use of or reliance on any
    //             such content, goods or services available on or through any such
    //             web sites or services.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             We strongly advise You to read the terms and conditions and
    //             privacy policies of any third-party web sites or services that
    //             You visit.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Termination
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             We may terminate or suspend Your access immediately, without
    //             prior notice or liability, for any reason whatsoever, including
    //             without limitation if You breach these Terms and Conditions.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Upon termination, Your right to use the Service will cease
    //             immediately.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Limitation of Liability
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Notwithstanding any damages that You might incur, the entire
    //             liability of the Company and any of its suppliers under any
    //             provision of this Terms and Your exclusive remedy for all of the
    //             foregoing shall be limited to the amount actually paid by You
    //             through the Service or 100 USD if You haven't purchased anything
    //             through the Service.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             To the maximum extent permitted by applicable law, in no event
    //             shall the Company or its suppliers be liable for any special,
    //             incidental, indirect, or consequential damages whatsoever
    //             (including, but not limited to, damages for loss of profits,
    //             revenues, goodwill, loss of data or other information, for
    //             business interruption, for personal injury, loss of privacy
    //             arising out of or in any way related to the use of or inability
    //             to use the Service, third-party software and/or third-party
    //             hardware used with the Service, or otherwise in connection with
    //             any provision of this Terms), even if the Company or any
    //             supplier has been advised of the possibility of such damages and
    //             even if the remedy fails of its essential purpose.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Some states do not allow the exclusion of implied warranties or
    //             limitation of liability for incidental or consequential damages,
    //             which means that some of the above limitations may not apply. In
    //             these states, each party's liability will be limited to the
    //             greatest extent permitted by law.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Services and Availability
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.InterpretationTitle}>Services</Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             During the Term, and subject to the terms and conditions of this
    //             Agreement, IDM NTS shall provide the Services to Customer and
    //             its Authorized Users as indicated on the Customer Acceptance
    //             Form solely for Customers internal business purposes.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "20px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             "AS IS" and "AS AVAILABLE" Disclaimer
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "30px", fontSize: "12px" }}>
    //           <Text style={{ marginTop: "30px", fontSize: "12px" }}>
    //             The Service is provided to You "AS IS" and "AS AVAILABLE" and
    //             with all faults and defects without warranty of any kind. To the
    //             maximum extent permitted under applicable law, the Company, on
    //             its own behalf and on behalf of its Affiliates and its and their
    //             respective licensors and service providers, expressly disclaims
    //             all warranties, whether express, implied, statutory or
    //             otherwise, with respect to the Service, including all implied
    //             warranties of merchantability, fitness for a particular purpose,
    //             title and non-infringement, and warranties that may arise out of
    //             course of dealing, course of performance, usage or trade
    //             practice. Without limitation to the foregoing, the Company
    //             provides no warranty or undertaking, and makes no representation
    //             of any kind that the Service will meet Your requirements,
    //             achieve any intended results, be compatible or work with any
    //             other software, applications, systems or services, operate
    //             without interruption, meet any performance or reliability
    //             standards or be error free or that any errors or defects can or
    //             will be corrected.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Without limiting the foregoing, neither the Company nor any of
    //             the company's provider makes any representation or warranty of
    //             any kind, express or implied: (i) as to the operation or
    //             availability of the Service, or the information, content, and
    //             materials or products included thereon; (ii) that the Service
    //             will be uninterrupted or error-free; (iii) as to the accuracy,
    //             reliability, or currency of any information or content provided
    //             through the Service; or (iv) that the Service, its servers, the
    //             content, or e-mails sent from or on behalf of the Company are
    //             free of viruses, scripts, trojan horses, worms, malware,
    //             timebombs or other harmful components.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Some jurisdictions do not allow the exclusion of certain types
    //             of warranties or limitations on applicable statutory rights of a
    //             consumer, so some or all of the above exclusions and limitations
    //             may not apply to You. But in such a case the exclusions and
    //             limitations set forth in this section shall be applied to the
    //             greatest extent enforceable under applicable law.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Governing Law
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             The laws of the Country, excluding its conflicts of law rules,
    //             shall govern this Terms and Your use of the Service. Your use of
    //             the Application may also be subject to other local, state,
    //             national, or international laws.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Disputes Resolution
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             If You have any concern or dispute about the Service, You agree
    //             to first try to resolve the dispute informally by contacting the
    //             Company.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             United States Legal Compliance
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             You represent and warrant that (i) You are not located in a
    //             country that is subject to the United States government embargo,
    //             or that has been designated by the United States government as a
    //             "terrorist supporting" country, and (ii) You are not listed on
    //             any United States government list of prohibited or restricted
    //             parties.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Indemnification
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.InterpretationTitle}>
    //             Indemnification by IDM Groups
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             IDM Groups shall defend Customer from and against any and all
    //             third-party claims, suits, actions or proceedings (each, a
    //             Claim) (i) that the use of the Services as permitted hereunder
    //             infringes, violates or misappropriates any copyright, patent,
    //             trademark, trade secret or other intellectual property right of
    //             a third party or (ii) arises due to the gross negligence or
    //             intentional misconduct of IDM Groups and shall indemnify
    //             Customer for any losses, damages and costs (including reasonable
    //             attorneys fees) (Losses) incurred by Customer in connection with
    //             any such Claim. IDM Groups will have no liability for any Claim
    //             to the extent it arises from (i) a modification of the Services
    //             by Customer or an Authorized User; (ii) use of the Services in
    //             violation of this Agreement or applicable law; (iii) use of the
    //             Services after IDM Groups notifies Customer to
    //           </Text>{" "}
    //           <View style={{ marginTop: "30px", fontSize: "12px" }}>
    //             <Text style={{ marginTop: "30px" }}>
    //               {" "}
    //               discontinue use because of an infringement or misappropriation
    //               claim; (iv) the combination, operation, or use of the Services
    //               with any other software, program, or device not provided or
    //               specified by IDM Groups to the extent such infringement would
    //               not have arisen but for such combination, operation, or use;
    //               or (v) Customers use of the Services in a manner that is
    //               inconsistent with its intended use. If the Services have
    //               become, or in IDM Groupss opinion is likely to become, the
    //               subject of any such Claim, IDM Groups may at its option and
    //               expense (a) procure for Customer the right to continue using
    //               the Services as set forth hereunder; (b) replace or modify the
    //               Services or certain functionalities to make it non-infringing;
    //               or (c) if options (a) or (b) are not reasonably practicable,
    //               terminate this Agreement. This Section 8(a) sets forth IDM
    //               Groupss sole liability (and Customers sole remedy) regarding
    //               infringement or misappropriation of third party rights.
    //             </Text>
    //           </View>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.InterpretationTitle}>
    //             Indemnification by Customer
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Customer shall defend IDM Groups from and against any and all
    //             Claims and shall indemnify IDM Groups from and against all
    //             Losses incurred by IDM Groups in connection with any such Claim,
    //             to the extent arising out of or alleging: (i) that the Customer
    //             Data or Customers use of the Services in violation of this
    //             Agreement infringes, violates or misappropriates any copyright,
    //             patent, trademark, trade secret or other intellectual property
    //             right of a third party; or (ii) Customers violation or breach of
    //             Section 2(e) of this Agreement.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.InterpretationTitle}>
    //             Indemnification Procedures
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             As a condition to any indemnification hereunder, the indemnified
    //             party shall promptly notify the indemnifying party in writing of
    //             any claim for which the indemnified party is seeking
    //             indemnification (provided that the failure to provide this
    //             notice will not relieve the indemnifying party of its
    //             indemnification obligations unless such failure materially
    //             prejudices the indemnifying partys ability to defend the Claim)
    //             and cooperate in the defense of such claim to the extent
    //             reasonably requested by the indemnifying party and at the
    //             indemnifying partys expense. The indemnifying party may assume
    //             control of the defense of such claim, provided, that the
    //             indemnified party shall have the right to participate in the
    //             defense or settlement of such claim with legal counsel of its
    //             choosing and at its own expense. The indemnifying party will not
    //             settle any claim that involves any remedy other than the payment
    //             of money by the indemnifying party (which consent may not be
    //             unreasonably withheld, delayed or rejected).
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Severability and Waiver
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "15px" }}>
    //           <Text style={styles.InterpretationTitle}>Severability</Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             If any provision of these Terms is held to be unenforceable or
    //             invalid, such provision will be changed and interpreted to
    //             accomplish the objectives of such provision to the greatest
    //             extent possible under applicable law and the remaining
    //             provisions will continue in full force and effect.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.InterpretationTitle}>Waiver</Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             Except as provided herein, the failure to exercise a right or to
    //             require performance of an obligation under these Terms shall not
    //             effect a party's ability to exercise such right or require such
    //             performance at any time thereafter nor shall the waiver of a
    //             breach constitute a waiver of any subsequent breach.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>
    //             Changes to These Terms and Conditions
    //           </Text>
    //         </View>
    //         <View
    //           style={{
    //             marginTop: "30px",
    //           }}
    //         >
    //           <Text style={styles.TermsandServiceText}>
    //             We reserve the right, at Our sole discretion, to modify or
    //             replace these Terms at any time. If a revision is material We
    //             will make reasonable efforts to provide at least 30 days' notice
    //             prior to any new terms taking effect. What constitutes a
    //             material change will be determined at Our sole discretion.
    //           </Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             By continuing to access or use Our Service after those revisions
    //             become effective, You agree to be bound by the revised terms. If
    //             You do not agree to the new terms, in whole or in part, please
    //             stop using the website and the Service.
    //           </Text>
    //         </View>
    //         <View style={{ marginTop: "10px" }}>
    //           <Text style={styles.IDMNTS_Termsof_ServiceTitle}>Contact Us</Text>
    //         </View>
    //         <View>
    //           <Text style={styles.TermsandServiceText}>
    //             If you have any questions about these Terms and Conditions, You
    //             can contact us:
    //           </Text>
    //         </View>
    //         <View style={styles.listItem}>
    //           <Text style={styles.listItemNumber}></Text>
    //           <Text style={styles.listItemText}>
    //             By email:<Link> support@idmnts.com</Link>
    //           </Text>
    //         </View>
    //       </View>
    //     </Page>
    //   </Document>
    // </PDFViewer>
    <div></div>
  );
}
