import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import UserPage from "../../../components/PageWrapper/UserPage";
import NotificationHistoryTable from "./Components/NotificationHistoryTable";

const NotificationHistory = () => {
  const navigate = useNavigate();
  return (
    <UserPage>
      <main className="notificationHistory">
        <header className="notificationHistory__headerContainer">
          <div className="notificationHistory__headerContainer__header">
            <IconButton
              onClick={() => navigate("/dashboard")}
              className="notificationHistory__headerContainer__header__icon"
            >
              <ArrowBackIcon sx={{ color: "black" }} />
            </IconButton>
            <h3 className="notificationHistory__headerContainer__header__heading">
              Notification History
            </h3>
          </div>
        </header>
        <section className="notificationHistory__box">
          <NotificationHistoryTable />
        </section>
      </main>
    </UserPage>
  );
};

export default NotificationHistory;
