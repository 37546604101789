import React from 'react';
import TextField from '@mui/material/TextField';
import { Button, duration } from '@mui/material';
import Selection from '../../../../components/Form/Selection';

const AdminPackageForm = ({ adminPackages, setAdminPackages, handleSubmit }) => {

  const durationOptions = [{ label: "1 Month", value: 0 }, { label: "3 Month", value: 1 }, { label: "6 Month", value: 2 }, { label: "12 Month", value: 3 }]
  const handleChange = e => {
    const { name, value } = e.target;
    setAdminPackages({
      ...adminPackages, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={adminPackages.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='number'
          name='price'
          label='Price'
          onChange={handleChange}
          value={adminPackages.price}
          required={true}
          style={{ width: '100%' }}
        />
        <Selection options={durationOptions} name={"duration"} label={"Select Duration"} attributes={adminPackages} handleChange={handleChange} />
        <TextField
          type='number'
          name='locations_creating_limit'
          label='Locations Limit'
          onChange={handleChange}
          value={adminPackages.locations_creating_limit}
          required={true}
          style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }}
        />
        <TextField
          type='number'
          name='endpoints_creating_limit'
          label='Endpoints Limit'
          onChange={handleChange}
          value={adminPackages.endpoints_creating_limit}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='number'
          name='endpoint_groups_creating_limit'
          label='Endpoint Groups Limit'
          onChange={handleChange}
          value={adminPackages.endpoint_groups_creating_limit}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='number'
          name='users_creating_limit'
          label='Users Limit'
          onChange={handleChange}
          value={adminPackages.users_creating_limit}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='promotion'
          label='Promotion'
          onChange={handleChange}
          value={adminPackages.promotion}
          // required={true}
          style={{ width: '100%' }}
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default AdminPackageForm;
