import React, { useState } from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AdminNotificationTabs from './Components/AdminNotificationTabs';
import useNotifications from '../../../hooks/useNotifications';
import AlertMessage from '../../../components/AlertMessage';

const AdminNotification = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createAdminNotification } = useNotifications();
  const [locationsList, setLocationsList] = useState([]);
  const [endpointGroupsList, setEndpointGroupsList] = useState([]);
  const [sendNotification, setSendNotification] = useState({});
  const [endpointsList, setEndpointsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notifications, setNotifications] = useState({
    locations_id: [],
    endpoint_groups_id: [],
  });


  const changeHandler = e => {
    const { name, value } = e.target;
    setNotifications({
      ...notifications, [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await createAdminNotification(sendNotification);
    if (response.status < 300) {
      navigate("/admin/dashboard");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='notification'>
        <header className='notification__headerContainer'>
          <div className='notification__headerContainer__header'>
            <IconButton onClick={() => navigate(-1)} className='notification__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='notification__headerContainer__header__heading'>Send Notification</h3>
          </div>
        </header>
        <section className='notification__box' >
          <AdminNotificationTabs locationsList={locationsList} setLocationsList={setLocationsList} endpointGroupsList={endpointGroupsList} setEndpointGroupsList={setEndpointGroupsList} changeHandler={changeHandler} notifications={notifications} templateId={id} setSendNotification={setSendNotification} endpointsList={endpointsList} setEndpointsList={setEndpointsList} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AdminNotification;
