import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useLocations from '../../../../hooks/useLocations';
import LocationDeleteModal from './LocationDeleteModal';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';

export default function LocationsTable() {
  const navigate = useNavigate();
  const { getAllLocations } = useLocations();
  const [locationsList, setLocationsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [locationData, setLocationData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? locationsList.filter((item) => item?.name.toLowerCase().includes(value.toLowerCase()) || item?.description.toLowerCase().includes(value.toLowerCase()))
        : locationsList
    setLocationData(searchFilter)
  }

  const getLocations = async () => {
    const response = await getAllLocations();
    if (response.status < 300) {
      setLocationsList(response.locations);
      setLocationData(response.locations);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead' >Name</TableCell>
              <TableCell className='table__tableHead' align="left">Description</TableCell>
              <TableCell className='table__tableHead' align="left">Web Signage</TableCell>
              <TableCell className='table__tableHead' align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell className='table__tableRow' align="left">{row.description}</TableCell>
                <TableCell className='table__tableRow' align="left">{row.web_signage?.name}</TableCell>
                <TableCell className='table__tableRow' style={{ display: "flex", justifyContent: "left" }}>
                  <IconButton onClick={() => navigate(`/admin/editlocation/${row.id}`)}>
                    <BorderColorIcon sx={{ color: "blue", fontSize: "1.2rem" }} />
                  </IconButton>
                  <LocationDeleteModal id={row.id} locationData={locationData} setLocationData={setLocationData} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={locationData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
