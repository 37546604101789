import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CompanyDeleteModal from './CompanyDeleteModal';
import useCompanies from '../../../../hooks/useCompanies';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { API_KEY } from '../../../../config/helpers/variables';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';

export default function CompaniesTable() {
  const navigate = useNavigate();
  const { getAllCompanies } = useCompanies();
  const [companiesList, setCompaniesList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [companyData, setCompanyData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? companiesList.filter((item) => item?.name.toLowerCase().includes(value.toLowerCase()) || item?.sub_domain.toLowerCase().includes(value.toLowerCase()))
        : companiesList
    setCompanyData(searchFilter)
  }


  const getCompanies = async () => {
    const response = await getAllCompanies();
    if (response.status < 300) {
      setCompaniesList(response.companies);
      setCompanyData(response.companies)
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);


  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead'>Company Id</TableCell>
              <TableCell className='table__tableHead'>Company Logo</TableCell>
              <TableCell className='table__tableHead'>Company Name</TableCell>
              <TableCell className='table__tableHead'>Sub Domain</TableCell>
              <TableCell className='table__tableHead'>Okta SSO</TableCell>
              <TableCell className='table__tableHead'>Status</TableCell>
              <TableCell className='table__tableHead'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row?.id}
                </TableCell>
                <TableCell className='table__tableRow' align="left"><img src={`${API_KEY}/${row?.logo?.url}`} alt='' /></TableCell>
                <TableCell className='table__tableRow' align="left">{row?.name}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.sub_domain}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.okta_sso_login ? "True" : "False"}</TableCell>
                <TableCell className='table__tableRow' align="left" style={{ textTransform: "capitalize" }}>{row?.status}</TableCell>
                <TableCell className='table__tableRow' style={{ display: "flex", justifyContent: "left" }}>
                  <IconButton onClick={() => navigate(`/admin/companies/${row.id}/users`)}>
                    <GroupOutlinedIcon sx={{ color: "blue", fontSize: "1.4rem" }} />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/admin/editcompany/${row.id}`)}>
                    <BorderColorIcon sx={{ color: "blue", fontSize: "1.2rem" }} />
                  </IconButton>
                  <CompanyDeleteModal id={row.id} companyData={companyData} setCompanyData={setCompanyData} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={companyData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
