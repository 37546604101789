import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AdminLocationTab from './AdminLocationTab';
import AdminDevicesTab from './AdminDevicesTab';
import AdminEndpointsTab from './AdminEndpointsTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AdminNotificationTabs({ locationsList, setLocationsList, endpointGroupsList, setEndpointGroupsList, notifications, changeHandler, templateId, setSendNotification, endpointsList, setEndpointsList, handleSubmit }) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding: "1rem", minHeight: "300px" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
          textColor="black"
          TabIndicatorProps={{ style: { background: 'rgba(0, 0, 0, 0.8)' } }}
        // sx={{ backgroundColor: "#F1F5F9", borderRadius: ".3rem .3rem 0 0" }}
        >
          <Tab style={{ textTransform: "capitalize", fontWeight: "", fontSize: "1rem" }} label="Location" {...a11yProps(0)} />
          <Tab style={{ textTransform: "capitalize", fontWeight: "", fontSize: "1rem" }} label="Devices" {...a11yProps(1)} />
          <Tab style={{ textTransform: "capitalize", fontWeight: "", fontSize: "1rem" }} label="Endpoints" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AdminLocationTab onChange={handleChange} next={1} locationsList={locationsList} setLocationsList={setLocationsList} changeHandler={changeHandler} notifications={notifications} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdminDevicesTab onChange={handleChange} next={2} prev={0} endpointGroupsList={endpointGroupsList} setEndpointGroupsList={setEndpointGroupsList} changeHandler={changeHandler} notifications={notifications} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AdminEndpointsTab onChange={handleChange} prev={1} notifications={notifications} template_id={templateId} setSendNotification={setSendNotification} endpointsList={endpointsList} setEndpointsList={setEndpointsList} handleSubmit={handleSubmit} />
      </TabPanel>
    </Box>
  );
}
