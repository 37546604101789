import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function SelectionMultiple({ options, handleChange, name, attributes, label }) {

  return (
    <div>
      <FormControl sx={{ mt: 4, width: '100%' }}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          type="number"
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          name={name}
          value={attributes[`${name}`]}
          onChange={handleChange}
          input={<OutlinedInput label="11111name11111111111111" />}
          MenuProps={MenuProps}
          required={true}
        >
          {options.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}