import React, { useState } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import useIntegration from '../../../../hooks/useIntegration';
import IntegrationForm from '../Components/IntegrationForm';
import AlertMessage from '../../../../components/AlertMessage';

const AddIntegration = () => {
  const { createIntegration } = useIntegration();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [integration, setIntegration] = useState({
    name: "",
    client_id: "",
    client_secret: "",
    base_url: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in integration) {
      if (!integration[prop]) return alert(`Please fill the form correctly!`);
    }
    const response = await createIntegration(integration);
    if (response.status < 300) {
      navigate("/admin/integration");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='integration'>
        <header className='integration__headerContainer'>
          <div className='integration__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/integration")} className='integration__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='integration__headerContainer__header__heading'>Add Integration</h3>
          </div>
        </header>
        <section className='integration__box'>
          <IntegrationForm integration={integration} setIntegration={setIntegration} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AddIntegration;
