import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import vmsg from 'vmsg';
import MicNoneIcon from '@mui/icons-material/MicNone';
import MicIcon from '@mui/icons-material/Mic';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
});

const TemplateForm = ({ template, setTemplate, handleSubmit }) => {
  const [record, setRecord] = useState({
    isLoading: false,
    isRecording: false,
    recording: null,
    recordingName: ""
  })

  const handleChange = e => {
    const { name, value } = e.target;
    setTemplate({
      ...template, [name]: name !== "audio" ? value : e.target.files[0]
    });
  };

  const handleRecording = async () => {
    setRecord({ ...record, isLoading: true });
    const today = new Date()
    const name_by_date = `${today.getFullYear()}` + `${(today.getMonth() + 1)}` + `${today.getDate()}` + `${today.getHours()}` + `${today.getMinutes()}` + `${today.getSeconds()}`
    if (record.isRecording) {
      const blob = await recorder.stopRecording()
      const audioBlob = await fetch(URL.createObjectURL(blob)).then((recording) => recording.blob());
      const audioFile = new File([audioBlob], `${name_by_date}_admin_voice.wav`, { type: 'audio/wav' });
      setTemplate({ ...template, audio: audioFile })
      setRecord({
        ...record,
        isLoading: false,
        isRecording: false,
        recording: URL.createObjectURL(blob),
        recordingName: audioFile.name
      })
    }
    else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        setRecord({ ...record, isLoading: false, isRecording: true })
      }
      catch (e) {
        setRecord({ ...record, isLoading: false })
      }
    }
  }

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={template.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='subject'
          label='Subject'
          onChange={handleChange}
          value={template.subject}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          multiline
          maxRows={4}
          type='text'
          name='body'
          label='Body'
          onChange={handleChange}
          value={template.body}
          required={true}
          style={{ width: '100%', marginBottom: '1rem' }}
        />
        <div className='templateForm__form__textFields'>
          <div className='templateForm__form__textFields__textField'>
            <span>Background color *</span>
            <TextField
              type='color'
              name='background_color'
              onChange={handleChange}
              value={template.background_color}
              required={true}
              style={{ width: '100%', marginBottom: '1rem' }}
            />
          </div>
          <div className='templateForm__form__textFields__textField'>
            <span>Font color *</span>
            <TextField
              type='color'
              name='font_color'
              onChange={handleChange}
              value={template.font_color}
              required={true}
              style={{ width: '100%', marginBottom: '1rem' }}
            />
          </div>
        </div>
        <div className='templateForm__form__fileUpload'>
          <span>Select file *</span>
          <br />
          <TextField
            type='file'
            name='audio'
            onChange={handleChange}
            // required={true}
            style={{ width: '100%' }}
          />
        </div>
        <div className='templateForm__form__voiceRecord'>
          {record.recordingName ?
            <span className='templateForm__form__voiceRecord__fileName'>File: {record.recordingName}</span>
            :
            <span className='templateForm__form__voiceRecord__fileName'>File: {template?.audio?.filename?.length > 40 ? template?.audio?.filename.slice(0, 40) : template?.audio?.filename}</span>
          }
          <div className='templateForm__form__voiceRecord__controls'>
            <div disabled={record.isLoading} onClick={handleRecording}>
              {record.isRecording ?
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='templateForm__form__voiceRecord__controls__container'>
                    <MicNoneIcon sx={{ fontSize: "1rem", color: "black" }} className='templateForm__form__voiceRecord__controls__container__circle' />
                    <div className='templateForm__form__voiceRecord__controls__container__text'>Recording</div>
                  </div>
                  <span><CancelIcon sx={{ fontSize: "1.8rem", color: "red", marginTop: ".3rem" }} /></span>
                </div>
                :
                <div className='templateForm__form__voiceRecord__controls__container'>
                  <MicIcon sx={{ fontSize: "1rem", color: "black" }} className='templateForm__form__voiceRecord__controls__container__circleTwo' />
                  <div className='templateForm__form__voiceRecord__controls__container__text'>Record</div>
                </div>
              }
            </div>
            {record.recording !== null &&
              <audio src={record.recording} controls style={{ filter: 'sepia(20 %) saturate(70%) grayscale(1) contrast(99%) invert(12%) ' }} />
            }
          </div>
        </div>
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default TemplateForm;
