import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SelectionMultiple from '../../../../components/Form/SelectionMultiple';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import useLocations from '../../../../hooks/useLocations';
import AlertMessage from '../../../../components/AlertMessage';

const LocationTab = ({ onChange, next, locationsList, setLocationsList, changeHandler, notifications }) => {
  const { getAllUserLocations } = useLocations();
  const [errorMessage, setErrorMessage] = useState(null)

  const locationOptions = locationsList.map((row) => ({ value: row.id, label: row.name }))
  const getLocations = async () => {
    const response = await getAllUserLocations();
    if (response.status < 300) {
      setLocationsList(response.locations);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <main className='locationTab'>
      <AlertMessage errorMessage={errorMessage} />
      <h1>Where do you want to send this message?</h1>
      <SelectionMultiple options={locationOptions} handleChange={changeHandler} name={"locations_id"} label={"Select Location"} attributes={notifications} />
      <div className='locationTab__buttons'>
        <Button onClick={(e) => onChange(e, next)} className='locationTab__buttons__next' variant='contained'>Next<SkipNextIcon sx={{ fontSize: '1rem', marginTop: '-.1rem' }} /></Button>
      </div>
    </main>
  );
}

export default LocationTab;
