import React, { useState } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ICMobileForm from '../Components/ICMobileForm';
import useICMobile from '../../../../hooks/useICMobile';
import AlertMessage from '../../../../components/AlertMessage';

const AddICMobile = () => {
  const { createICMobile } = useICMobile();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [icMobile, setICMobile] = useState({
    name: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in icMobile) {
      if (!icMobile[prop]) return alert(`Please fill the form correctly!`);
    };
    const response = await createICMobile(icMobile);
    if (response.status < 300) {
      navigate("/admin/icmobile");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }

  }

  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='icMobile'>
        <header className='icMobile__headerContainer'>
          <div className='icMobile__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/icmobile")} className='icMobile__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='icMobile__headerContainer__header__heading'>Add IC Mobile</h3>
          </div>
        </header>
        <section className='icMobile__box'>
          <ICMobileForm icMobile={icMobile} setICMobile={setICMobile} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default AddICMobile;
