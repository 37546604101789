import { Paper } from '@mui/material';
import React from 'react';
import AdminPage from '../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

const Logs = () => {
  const navigate = useNavigate();
  return (
    <AdminPage>
      <main className='logs'>
        <header className='logs__headerContainer'>
          <div className='logs__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='logs__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='logs__headerContainer__header__heading'>Logs</h3>
          </div>
        </header>
        <Paper className='logs__box'>
        </Paper>
      </main>
    </AdminPage>
  );
}

export default Logs;
