import React, { useState, useEffect } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import UserWebSignageForm from '../Components/UserWebSignageForm';
import useWebSignages from '../../../../hooks/useWebSignages';
import AlertMessage from '../../../../components/AlertMessage';

const EditUserWebSignage = () => {
  const { getUserWebSignageById, updateUserWebSignage } = useWebSignages();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateWebSignages, setUpdateWebSignages] = useState({
    name: "",
    scroller_speed: "",
    landscape_title_width: "",
    landscape_title_height: "",
    landscape_title_top: "",
    landscape_title_left: "",
    landscape_description_width: "",
    landscape_description_height: "",
    landscape_description_top: "",
    landscape_description_left: "",
    potrait_title_width: "",
    potrait_title_height: "",
    potrait_title_top: "",
    potrait_title_left: "",
    potrait_description_width: "",
    potrait_description_height: "",
    potrait_description_top: "",
    potrait_description_left: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateUserWebSignage(updateWebSignages);
    if (response.status < 300) {
      navigate("/userwebsignages")
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getWebSignage = async () => {
    const response = await getUserWebSignageById(id);
    if (response.status < 300) {
      setUpdateWebSignages(response.web_signage);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getWebSignage();
  }, []);


  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='webSignage'>
        <header className='webSignage__headerContainer'>
          <div className='webSignage__headerContainer__header'>
            <IconButton onClick={() => navigate("/userwebsignages")} className='webSignage__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='webSignage__headerContainer__header__heading'>Edit Web Signage</h3>
          </div>
        </header>
        <section className='webSignage__box'>
          <UserWebSignageForm webSignage={updateWebSignages} setWebSignage={setUpdateWebSignages} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default EditUserWebSignage;
