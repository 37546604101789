import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ICMobileForm from '../Components/ICMobileForm';
import useICMobile from '../../../../hooks/useICMobile';
import AlertMessage from '../../../../components/AlertMessage';

const EditICMobile = () => {
  const { getICMobileById, updateICMobile } = useICMobile();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateICMobiles, setUpdateICMobiles] = useState({
    name: ""
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateICMobile(updateICMobiles);
    if (response.status < 300) {
      navigate("/admin/icmobile")
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getICMobile = async () => {
    const response = await getICMobileById(id);
    if (response.status < 300) {
      setUpdateICMobiles(response.ic_mobile);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getICMobile();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='icMobile'>
        <header className='icMobile__headerContainer'>
          <div className='icMobile__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/icmobile")} className='icMobile__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='icMobile__headerContainer__header__heading'>Edit IC Mobile</h3>
          </div>
        </header>
        <section className='icMobile__box'>
          <ICMobileForm icMobile={updateICMobiles} setICMobile={setUpdateICMobiles} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditICMobile;
