import React, { useEffect } from 'react'
import usePackages from '../../../../hooks/usePackages'
import { useNavigate, useLocation } from 'react-router-dom'

function PaymentSuccess() {
    const params = useLocation().search;
    const navigate = useNavigate();
    const { redirectRequest } = usePackages();

    const redirectPage = async () => {
        const response = await redirectRequest(params.split('?')[1]);
        if (response.status === 200) {
            setTimeout(() => {
                navigate('/dashboard')
            }, 5000);
        }

    }
    useEffect(() => {
        redirectPage();
    }, [])

    return (
        <div className='paymentSuccess'>
            <h2>Payment Successful 🎉</h2>
        </div>
    )
}

export default PaymentSuccess