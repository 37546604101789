import axios from 'axios';
import { API_KEY, ADMIN_STORAGE_KEY } from '../config//helpers/variables';
import useHandleErrors from './useHandleErrors';

const useCompanies = () => {
  const { handleErrors } = useHandleErrors();
  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const getAdminHeaders = () => {
    if (admin) {
      return {
        headers: {
          Authorization: admin.token,
        },
      };
    }
  };

  const createCompany = async company => {
    const formData = new FormData()
    for (const property in company) {
      formData.append(
        property, company[property]
      )
    }
    const response = await axios.post(
      `${API_KEY}/admins/companies`, formData,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getAllCompanies = async () => {
    const response = await axios.get(
      `${API_KEY}/admins/companies`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };


  const getAllCompanyUser = async id => {
    const response = await axios.get(
      `${API_KEY}/admins/companies/${id}/company_users`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const deleteCompany = async id => {
    const response = await axios.delete(
      `${API_KEY}/admins/companies/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const updateCompany = async (company, id) => {
    const formData = new FormData()
    for (const property in company) {
      formData.append(
        property, company[property]
      )
    }
    const response = await axios.put(
      `${API_KEY}/admins/companies/${id}`,
      formData,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  const getCompanyById = async (id) => {
    const response = await axios.get(
      `${API_KEY}/admins/companies/${id}`,
      getAdminHeaders()
    ).then((res) => {
      if (res.data.status > 300) {
        handleErrors(res);
      }
      return res.data
    })
    return response;
  };

  return {
    createCompany, getAllCompanies, deleteCompany, updateCompany, getCompanyById, getAllCompanyUser
  };
};
export default useCompanies;
