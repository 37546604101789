import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Selection from '../../../../components/Form/Selection';
import useLocations from '../../../../hooks/useLocations';
import useEndpointGroups from '../../../../hooks/useEndpointGroups'
import { Button } from '@mui/material';
import AlertMessage from '../../../../components/AlertMessage';

const EndpointGroupForm = ({ endpoint, setEndpoint, handleSubmit }) => {

  const { getAllLocations } = useLocations();
  const { getAllEndpointGroups } = useEndpointGroups();
  const [errorMessage, setErrorMessage] = useState(null)
  const [locationsList, setLocationsList] = useState([]);
  const [endpointGroupsList, setEndpointGroupsList] = useState([]);

  const destinationOptions = [{ value: 0, label: "" }, { value: 1, label: "IC Mobile" }, { value: 2, label: "Signage" }]
  const deviceOptions = [{ value: 0, label: "" }, { value: 1, label: "Android" }, { value: 2, label: "Apple" }, { value: 3, label: "Windows" }, { value: 4, label: "Phone Call" }, { value: 5, label: "Apple Desktop" }, { value: 6, label: "Singlewire" }]
  const locationOptions = locationsList.map((row) => ({ value: row.id, label: row.name }))
  const endpointGroupsOptions = endpointGroupsList.map((row) => ({ value: row.id, label: row.name }))

  const handleChange = e => {
    const { name, value } = e.target;
    (["destination_type", "resource_url", "network_distribution_id"].includes(name)) ? endpoint['destination'][`${name}`] = value :
      endpoint[`${name}`] = value
    setEndpoint({ ...endpoint })
  };

  const getLocations = async () => {
    const response = await getAllLocations();
    if (response.status < 300) {
      setLocationsList(response.locations);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  const getEndpointGroups = async () => {
    const response = await getAllEndpointGroups();
    if (response.status < 300) {
      setEndpointGroupsList(response.endpoint_groups);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getLocations();
    getEndpointGroups();
  }, []);

  return (
    <main className='templateForm'>
      <AlertMessage errorMessage={errorMessage} />
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={endpoint.name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          multiline
          maxRows={4}
          type='text'
          name='description'
          label='Description'
          onChange={handleChange}
          value={endpoint.description}
          required={true}
          style={{ width: '100%' }}
        />
        <Selection handleChange={handleChange} options={locationOptions} name={"location_id"} attributes={endpoint} label={"Location"} />
        <Selection handleChange={handleChange} options={endpointGroupsOptions} name={"endpoint_group_id"} attributes={endpoint} label={"Endpoint Group"} />
        <Selection handleChange={handleChange} options={destinationOptions} name={"destination_type"} attributes={endpoint?.destination} label={"Destination Type"} />
        <TextField
          type='text'
          name='resource_url'
          label='Resource url'
          onChange={handleChange}
          value={endpoint?.destination?.resource_url}
          required={true}
          style={{ width: '100%', marginTop: "2rem" }}
        />
        <TextField
          type='number'
          name='network_distribution_id'
          label='Network Distribution'
          onChange={handleChange}
          value={endpoint?.destination?.network_distribution_id}
          required={true}
          style={{ width: '100%', marginTop: "2rem" }}
        />
        <Selection handleChange={handleChange} options={deviceOptions} name={"device_type"} attributes={endpoint?.device_type} label={"Device Type"} />
        <TextField
          type='text'
          name='device_identifier'
          label='Device Identifier'
          onChange={handleChange}
          value={endpoint?.device_identifier}
          required={true}
          style={{ width: '100%', marginTop: "2rem" }}
        />
        <TextField
          type='text'
          name='build_no'
          label='Build#'
          onChange={handleChange}
          value={endpoint?.build_no}
          required={true}
          style={{ width: '100%', marginTop: "2rem" }}
        />
        <TextField
          type='text'
          name='os'
          label='OS'
          onChange={handleChange}
          value={endpoint?.os}
          required={true}
          style={{ width: '100%', marginTop: "2rem" }}
        />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default EndpointGroupForm;
