import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import UserPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DistributionListForm from '../Components/DistributionListForm';
import useDistributionList from '../../../../hooks/useDistributionList';
import AlertMessage from '../../../../components/AlertMessage';

const EditDistributionList = () => {

  const { getDistributionListById, updateDistributionList } = useDistributionList();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateDistributionLists, setUpdateDistributionLists] = useState({
    name: "",
    is_subscribable: "",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateDistributionList(updateDistributionLists, id);
    if (response.status < 300) {
      navigate("/distributionList");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getDistributionList = async () => {
    const response = await getDistributionListById(id);
    if (response.status < 300) {
      setUpdateDistributionLists(response.distribution_list);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getDistributionList();
  }, []);


  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='distributionList'>
        <header className='distributionList__headerContainer'>
          <div className='distributionList__headerContainer__header'>
            <IconButton onClick={() => navigate("/distributionList")} className='distributionList__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='distributionList__headerContainer__header__heading'>Edit Distribution List</h3>
          </div>
        </header>
        <section className='distributionList__box'>
          <DistributionListForm distributionList={updateDistributionLists} setDistributionList={setUpdateDistributionLists} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default EditDistributionList;
