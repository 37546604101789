import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import usePackages from '../../../../hooks/usePackages';
import AdminPackageDeleteModal from './AdminPackageDeleteModal';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TablePagination from '@mui/material/TablePagination';
import AlertMessage from '../../../../components/AlertMessage';

export default function AdminPackagesTable() {
  const navigate = useNavigate();
  const { getPackages } = usePackages();
  const [packagesList, setPackagesList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [adminPackagesData, setAdminPackagesData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangeSearch = (e) => {
    const value = e.target.value
    const searchFilter =
      value !== "" ? packagesList.filter((item) => item?.name.toLowerCase().includes(value.toLowerCase()))
        : packagesList
    setAdminPackagesData(searchFilter)
  }

  const getPackageList = async () => {
    const response = await getPackages();
    if (response.status < 300) {
      setPackagesList(response.packages);
      setAdminPackagesData(response.packages);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getPackageList();
  }, []);

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className='tableSearchBox'>
        <input
          type='text'
          placeholder='Search'
          onChange={handleChangeSearch}
          name='search'
          className='tableSearchBox__searchInput' />
        <SearchOutlinedIcon className='tableSearchBox__searchIcon' />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
          <TableHead style={{ backgroundColor: "black", color: "white" }}>
            <TableRow>
              <TableCell className='table__tableHead' >Name</TableCell>
              <TableCell className='table__tableHead' align="left">Price</TableCell>
              <TableCell className='table__tableHead' align="left">Duration</TableCell>
              <TableCell className='table__tableHead' align="left">Locations Limit</TableCell>
              <TableCell className='table__tableHead' align="left">Endpoints Limit</TableCell>
              <TableCell className='table__tableHead' align="left">Endpoint Groups Limit</TableCell>
              <TableCell className='table__tableHead' align="left">Users Limit</TableCell>
              <TableCell className='table__tableHead' align="left">Promotion</TableCell>
              <TableCell className='table__tableHead' align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminPackagesData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table__tableRow' component="th" scope="row">
                  {row?.name}
                </TableCell>
                <TableCell className='table__tableRow' align="left">{row?.price}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.duration}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.locations_creating_limit}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.endpoints_creating_limit}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.endpoint_groups_creating_limit}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.users_creating_limit}</TableCell>
                <TableCell className='table__tableRow' align="left">{row?.promotion}</TableCell>
                <TableCell className='table__tableRow' style={{ display: "flex", justifyContent: "left" }}>
                  <IconButton onClick={() => navigate(`/admin/packages/${row.id}/edit`)}>
                    <BorderColorIcon sx={{ color: "blue", fontSize: "1.2rem" }} />
                  </IconButton>
                  <AdminPackageDeleteModal id={row.id} adminPackagesData={adminPackagesData} setAdminPackagesData={setAdminPackagesData} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={adminPackagesData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
