import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import useProfile from '../../../../hooks/useProfile';
import AdminProfileForm from '../Components/AdminProfileForm';
import { ADMIN_STORAGE_KEY } from '../../../../config/helpers/variables';
import AlertMessage from '../../../../components/AlertMessage';

const EditAdminProfile = () => {
  const { updateAdminProfile } = useProfile();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [updateProfile, setUpdateProfile] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: ""
  });

  const admin = JSON.parse(sessionStorage.getItem(ADMIN_STORAGE_KEY));

  const handleSubmit = async e => {
    e.preventDefault();
    for (let prop in updateProfile) {
      if (!updateProfile[prop]) return alert(`Please fill the form correctly!`);
    };
    const response = await updateAdminProfile(updateProfile);
    if (response.status < 300) {
      navigate("/admin/signin");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getAdminProfile = async () => {
    setUpdateProfile(admin.data);
  }

  useEffect(() => {
    getAdminProfile();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='profile'>
        <header className='profile__headerContainer'>
          <div className='profile__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='profile__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='profile__headerContainer__header__heading'>Edit Profile</h3>
          </div>
        </header>
        <section className='profile__box'>
          <AdminProfileForm admin={updateProfile} setAdmin={setUpdateProfile} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditAdminProfile;
