import React from 'react';
import Avatar from '@mui/material/Avatar';

const UserProfile = ({ user }) => {
 
  return (
    <section className='profileCard'>
      <header className='profileCard__header'>
        <h1 className='profileCard__header__heading'>My Profile</h1>
      </header>
      <Avatar alt="" src="" className='profileCard__avatar' />
      <h1 className='profileCard__name'>{user?.data?.first_name} {user?.data?.last_name}</h1>
      <span className='profileCard__email'>{user?.data?.email}</span>
      <span className='profileCard__email'>{user?.data?.role}</span>
    </section>
  );
}

export default UserProfile;
