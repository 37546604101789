import React, { useState } from "react";
import CFWButton from "../../../../components/Form/CFWButton";
import CInput from "../../../../components/Form/CInput";
import useAuthenticate from "../../../../hooks/useAuthenticate";
import AlertMessage from "../../../../components/AlertMessage";
import { useNavigate } from "react-router-dom";

const AdminSignIn = () => {
  const { adminLogin } = useAuthenticate();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [admin, setAdmin] = useState({
    email: "",
    password: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setAdmin({ ...admin, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    for (let prop in admin) {
      if (!admin[prop]) return alert("Please fill the form correctly");
    }
    const response = await adminLogin(admin);
    if (response) {
      navigate("/admin/dashboard");
    } else if (response?.data?.status?.message == undefined) {
      setErrorMessage("Something went wrong!");
    }
  };

  const handledownload = () => {
    navigate("/terms&services");
  };

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className="authentication">
        <img
          src={require("../../../../assets/house.jpg")}
          className="authentication--bg"
          alt=""
        />
        <div className="authentication__box">
          <div className="authentication__box__left">
            <h1> Admin Sign In</h1>
            <form onSubmit={submitHandler}>
              <CInput
                type="email"
                placeholder="Email"
                name="email"
                label="Email"
                value={admin.email}
                onChange={changeHandler}
                required={true}
              />
              <CInput
                type="password"
                name="password"
                placeholder="Password"
                label="Password"
                value={admin.password}
                onChange={changeHandler}
                required={true}
                autoComplete="off"
              />
              <CFWButton title="Sign In" classes="theme-btn" type="submit" />
            </form>
            <p className="authentication__box__left__terms">
              <b>NTS</b> by <b>IDM Groups </b> By using NTS Service you accept
              the <a href="/terms_and_services" target="_blank">Terms of Service</a>
            </p>
          </div>
          <div className="authentication__box__right">
            <img
              className="authentication__box__right__logo"
              src={require("../../../../assets/NTS LOGO.png")}
              alt="NTS Logo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSignIn;
