import React, { useState, useEffect } from 'react';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AdminUserForm from '../Components/AdminUserForm';
import useUsers from '../../../../hooks/useUsers';
import AlertMessage from '../../../../components/AlertMessage';

const EditAdminUser = () => {
  const { getAdminUserById, updateAdminUser } = useUsers();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateUsers, setUpdateUsers] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    company_id: "",
    status: "",
    role: "Administrator",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      ...updateUsers,
    };
    const response = await updateAdminUser(data);
    if (response.status < 300) {
      navigate("/admin/users");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }

  }

  const getlUser = async () => {
    const response = await getAdminUserById(id);
    if (response.status < 300) {
      setUpdateUsers(response.user);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getlUser();
  }, []);


  return (
    <AdminPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='users'>
        <header className='users__headerContainer'>
          <div className='users__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/users")} className='users__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='users__headerContainer__header__heading'>Edit User</h3>
          </div>
        </header>
        <section className='users__box'>
          <AdminUserForm user={updateUsers} setUser={setUpdateUsers} handleSubmit={handleSubmit} />
        </section>
      </main>
    </AdminPage>
  );
}

export default EditAdminUser;