import React from 'react';
import TextField from '@mui/material/TextField';
import Selection from '../../../../components/Form/Selection';
import { Button } from '@mui/material';

const DistributionListForm = ({ distributionList, setDistributionList, handleSubmit }) => {
  const subscriptionOptions = [{ label: 'Yes', value: "true" }, { label: 'No', value: "false" }]

  const handleChange = e => {
    const { name, value } = e.target;
    setDistributionList({
      ...distributionList, [name]: value
    });
  };

  return (
    <main className='templateForm' >
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='name'
          label='Name'
          onChange={handleChange}
          value={distributionList.name}
          required={true}
          style={{ width: '100%' }}
        />
        <Selection handleChange={handleChange} options={subscriptionOptions} name={"is_subscribable"} attributes={distributionList} label={"Subscribable"} />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main >
  );
}

export default DistributionListForm;
