import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../../../components/AlertMessage";
import CFWButton from "../../../../components/Form/CFWButton";
import CInput from "../../../../components/Form/CInput";
import useAuthenticate from "../../../../hooks/useAuthenticate";

const SignIn = () => {
  const { userLogin } = useAuthenticate();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const changeHandler = event => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const submitHandler = async e => {
    e.preventDefault();
    for (let prop in user) {
      if (!user[prop]) return alert('Please fill the form correctly');
    }
    const response = await userLogin(user);
    if (response?.data?.status?.code < 300) {
      navigate("/dashboard");
    } else if (response?.data?.message !== undefined) {
      setErrorMessage(response?.data?.message);
    } else if (response?.data?.status?.message == undefined) {
      setErrorMessage("Something went wrong!");
    }
  };

  return (
    <>
      <AlertMessage errorMessage={errorMessage} />
      <div className='authentication'>
        <img
          src={require("../../../../assets/house.jpg")}
          className='authentication--bg'
          alt=''
        />
        <div className='authentication__box'>
          <div className='authentication__box__left'>
            <h1>User Sign In</h1>
            <form onSubmit={submitHandler}>
              <CInput
                type='email'
                placeholder='Email'
                name='email'
                label='Email'
                value={user.email}
                onChange={changeHandler}
                required={true}
              />
              <CInput
                type='password'
                name='password'
                placeholder='Password'
                label='Password'
                value={user.password}
                onChange={changeHandler}
                required={true}
                autoComplete='off'
              />
              <CFWButton title='Sign In' classes='theme-btn' type='submit' />
            </form>
            <p className='authentication__box__left__terms'><b>NTS</b> by <b>IDM Groups </b> By using NTS Service you accept the  <a href="/terms_and_services" target="_blank">Terms of Service</a></p>
          </div>
          <div className='authentication__box__right'>
            {/* <h1>Notification Triggering <br /> System</h1> */}
            <img
              className="authentication__box__right__logo"
              src={require("../../../../assets/NTS LOGO.png")}
              alt="NTS Logo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
