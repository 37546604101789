import { Button } from '@mui/material';
import React from 'react';
import UserPage from '../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IntegrationTable from './Components/IntegrationTable';

const UserIntegration = () => {
  const navigate = useNavigate();
  return (
    <UserPage>
      <main className='integration'>
        <header className='integration__headerContainer'>
          <div className='integration__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/dashboard")} className='integration__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='integration__headerContainer__header__heading'>Integration</h3>
          </div>
          <div className='integration__headerContainer__submitButton'>
            <Button onClick={() => navigate("/user/addintegration")}><AddIcon sx={{ fontSize: "1.2rem", marginRight: ".2rem", marginLeft: "-.3rem" }} />Integration</Button>
          </div>
        </header>
        <section className='integration__box'>
          <IntegrationTable />
        </section>
      </main>
    </UserPage>
  );
}

export default UserIntegration;
