import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AdminPage from '../../../../components/PageWrapper/AdminPage';
import UsersListTable from '../Components/UsersListTable';

const UsersView = () => {
  const navigate = useNavigate();
  return (
    <AdminPage>
      <main className='companies'>
        <header className='companies__headerContainer'>
          <div className='companies__headerContainer__header'>
            <IconButton onClick={() => navigate("/admin/companies")} className='companies__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='companies__headerContainer__header__heading'>Users</h3>
          </div>
        </header>
        <section className='companies__box'>
          <UsersListTable />
        </section>
      </main>
    </AdminPage>
  );
}

export default UsersView;
