import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";

const ViewNotificationHistory = () => {
  const navigate = useNavigate();
  return (
    <>
      <main className="users">
        <header className="users__headerContainer">
          <div className="users__headerContainer__header">
            <IconButton
              onClick={() => navigate("/notificationhistory")}
              className="users__headerContainer__header__icon"
            >
              <ArrowBackIcon sx={{ color: "black" }} />
            </IconButton>
            <h3 className="users__headerContainer__header__heading">
              View Notification History
            </h3>
          </div>
        </header>
        <section className="users__box">
          <form className="templateForm__form">
            <TextField
              type="text"
              name="user__name"
              label="User Name"
              disabled
              style={{ width: "100%", marginBottom: "2rem" }}
            />
            <TextField
              type="text"
              name="templateName"
              label="Template Name"
              disabled
              style={{ width: "100%", marginBottom: "2rem" }}
            />
            <TextField
              type="text"
              name="endpoint__name"
              label="EndPoint Name"
              disabled
              style={{ width: "100%", marginBottom: "2rem" }}
            />
             <TextField
              type="text"
              name="location"
              label="Location"
              disabled
              style={{ width: "100%", marginBottom: "2rem" }}
            />
            <TextField
              type="text"
              name="creation__date"
              label="Creation Date"
              disabled
              style={{ width: "100%", marginBottom: "2rem" }}
            />
            <TextField
          id="outlined-multiline-flexible"
          label="Notes"
          multiline
          maxRows={10}
          style={{ width: "100%", marginBottom: "2rem" }}
          disabled
        />
          </form>
          
        </section>
      </main>
    </>
  );
};

export default ViewNotificationHistory;
