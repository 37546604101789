import React from 'react';
import TextField from '@mui/material/TextField';
import Selection from '../../../../components/Form/Selection';
import { Button } from '@mui/material';

const UserForm = ({ user, setUser, handleSubmit }) => {

  const administratorRoleOptions = [{ value: 1, label: "Administrator" }, { value: 0, label: "Notification User" }]

  const userStatusOptions = [{ value: 1, label: "Active" }, { value: 0, label: "Inactive" }]

  const handleChange = e => {
    const { name, value } = e.target;
    setUser({
      ...user, [name]: value
    });
  };

  return (
    <main className='templateForm'>
      <form className='templateForm__form' onSubmit={handleSubmit}>
        <TextField
          type='text'
          name='first_name'
          label='First Name'
          onChange={handleChange}
          value={user.first_name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='text'
          name='last_name'
          label='Last Name'
          onChange={handleChange}
          value={user.last_name}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='email'
          name='email'
          label='Email'
          onChange={handleChange}
          value={user.email}
          required={true}
          style={{ width: '100%', marginBottom: '2rem' }}
        />
        <TextField
          type='password'
          name='password'
          label='Password'
          onChange={handleChange}
          value={user.password}
          required={true}
          autoComplete='off'
          style={{ width: '100%' }}
        />
        <Selection handleChange={handleChange} options={administratorRoleOptions} name={"role"} attributes={user} label={"Select Role"} />
        <Selection handleChange={handleChange} options={userStatusOptions} name={"status"} attributes={user} label={"Select Status"} />
        <div className='templateForm__form__buttons' >
          <Button variatn='contained' type="submit" className='templateForm__form__buttons__submitButton' >Submit</Button>
        </div>
      </form>
    </main>
  );
}

export default UserForm;