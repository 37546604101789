import React, { useState, useEffect } from 'react';
import UserPage from '../../../../components/PageWrapper/UserPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import TemplateForm from '../Components/TemplateForm';
import useTemplate from '../../../../hooks/useTemplate';
import AlertMessage from '../../../../components/AlertMessage';

const EditTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getTemplateById, updateTemplate } = useTemplate();
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateTemplates, setUpdateTemplates] = useState({
    name: "",
    subject: "",
    body: "",
    audio: "",
    font_color: "#808080",
    background_color: "#FFFFFF",
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let data = {
      ...updateTemplates,
    };
    const response = await updateTemplate(data);
    if (response.status < 300) {
      navigate("/dashboard");
    } else if (response.status > 300) {
      setErrorMessage(response.message);
    }
  }

  const getTemplates = async () => {
    const response = await getTemplateById(id);
    if (response.status < 300) {
      setUpdateTemplates(response.template);
    } else {
      setErrorMessage('Something went wrong!')
    }
  }

  useEffect(() => {
    getTemplates();
  }, []);


  return (
    <UserPage>
      <AlertMessage errorMessage={errorMessage} />
      <main className='template'>
        <header className='template__headerContainer'>
          <div className='template__headerContainer__header'>
            <IconButton onClick={() => navigate("/dashboard")} className='template__headerContainer__header__icon'>
              <ArrowBackIcon sx={{ color: 'black' }} />
            </IconButton>
            <h3 className='template__headerContainer__header__heading'>Edit Template</h3>
          </div>
        </header>
        <section className='template__box'>
          <TemplateForm template={updateTemplates} setTemplate={setUpdateTemplates} handleSubmit={handleSubmit} />
        </section>
      </main>
    </UserPage>
  );
}

export default EditTemplate;
